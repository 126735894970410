import React, { useState, useEffect, useRef } from 'react';
//import AmbientMusicCard from '../components/AmbientMusicCard';
//import MeditationCard from '../components/MeditationCard';
//import MeditationSearchBox from '../components/MeditationSearchBox';
import Menu from '../components/Menu';
import Footer from '../components/Footer';
//import ArticleBanner from '../components/ArticleBanner';
import styled from 'styled-components'; // package to define css class
import $ from 'jquery'; // package to run jQuery
import img from '../images/mindful/walk.jpg';
//import img_event_1 from '../images/chillful/event_1.jpg';
//import pattern_background from '../images/general/pattern_background.jpg';
//import demo1 from '../images/mp3/demo1.mp3';
//import { storage, db } from '../firebase';
import { db } from '../firebase.js';
//import Slider from 'infinite-react-carousel';
//import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import PopupModal from '../components/PopupModal';
import { useTranslation } from 'react-i18next';
import {
  isDesktop,
  isIOS
} from "react-device-detect";
//import { Helmet, HelmetProvider } from "react-helmet-async";

// define css class
/*
const ChillfulMargin10 = styled.div`
  height: 10px;
`;
const ChillfulMargin40 = styled.div`
  height: 40px;
`;
*/
const ChillfulBanner = styled.div`
  margin-top: 30px;
  margin-left: 100px;
  margin-right: 100px;
  height: 550px;
  background-size: cover;
  background-image: url(${img});
  border-radius: 30px;
  @media only screen and (max-width: 1200px) {
    margin-left: 60px;
    margin-right: 60px;
  }
  @media only screen and (max-width: 1024px) {
    margin-left: 30px;
    margin-right: 30px;
  }
  @media only screen and (max-width: 768px) {
    margin-left: 20px;
    margin-right: 20px;
  }
  @media only screen and (max-width: 480px) {
    margin-left: 20px;
    margin-right: 20px;
  }
`;

const BannerAudio = styled.audio`
  margin-top: 30px;
  width: 500px;
  @media only screen and (max-width: 1025px) {
    width: 90%;
  }
  @media only screen and (max-width: 768px) {
    width: 90%;
  }
  @media only screen and (max-width: 480px) {
    width: 90%;
  }
`;

const ChillfulMargin160 = styled.div`
  height: 160px;
`;
const ChillfulMargin120 = styled.div`
  height: 120px;
`;
const ChillfulBannerText = styled.div`
  margin-left: 180px;
  margin-right: 180px;
  color: white;
  transition: all 0.5s;
  @media only screen and (max-width: 1100px) {
    margin-left: 100px;
    margin-right: 100px;
  }
  @media only screen and (max-width: 800px) {
    margin-left: 30px;
    margin-right: 30px;
  }
`;
const ChillfulBannerText1 = styled.span`
  margin-left: 10px;
  color: #ff9700;
  font-weight: 800;
  font-size: 24px;
  transition: all 0.5s;
  @media only screen and (max-width: 800px) {
    font-size: 18px;
  }
`;
const ChillfulBannerText2 = styled.p`
  font-weight: 600;
  font-size: 42px;
  margin-top: 10px;
  transition: all 0.5s;
  max-width: 700px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  @media only screen and (max-width: 800px) {
    font-size: 36px;
  }
`;
const ChillfulBannerText3 = styled.p`
  font-weight: 500;
  font-size: 24px;
  margin-top: 5px;
  max-width: 500px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  transition: all 0.5s;
  @media only screen and (max-width: 800px) {
    font-size: 20px;
  }
`;
const ChillfulBannerText4 = styled.p`
  font-weight: 500;
  font-size: 20px;
  margin-top: 15px;
  transition: all 0.5s;
  @media only screen and (max-width: 800px) {
    font-size: 16px;
  }
`;
/*
const ChillfulBannerBtn = styled.div`
  margin-top: 15px;
  background-color: #ff9700;
  padding: 10px 20px;
  width: 100px;
  border-radius: 50px;
  cursor: pointer;
  @media only screen and (max-width: 800px) {
    padding: 9px 18px;
    width: 92px;
  }
`;
const ChillfulBannerBtnIcon = styled.img`
  width: 17px;
  vertical-align: middle;
  margin-bottom: 6px;
  margin-right: 4px;
  @media only screen and (max-width: 800px) {
    font-size: 16px;
  }
`;
const ChillfulBannerBtnText = styled.span`
  margin-left: 5px;
  font-size: 18px;
  @media only screen and (max-width: 800px) {
    font-size: 16px;
  }
`;
*/
const ChillfulBannerIcon = styled.img`
  height: 24px;
  transition: all 0.5s;
  @media only screen and (max-width: 800px) {
    height: 18px;
  }
`;
const ChillfulBody = styled.div`
  width: 100%;
`;
/*
const ChillfulBodySubHeading = styled.div`
  text-align: center;
`;
const ChillfulBodySubHeadingText1 = styled.p`
  font-size: 20px;
  color: #444;
  font-weight: 900;
`;
const ChillfulBodySubHeadingText2 = styled.p`
  font-size: 50px;
  margin-bottom: 5px;
  display: inline;
  color: #ff9700;
  font-weight: 600;
`;
const ChillfulBodySubHeadingText3 = styled.p`
  font-size: 24px;
  line-height: 26px;
  color: #444;
  transition: all 0.5s;
  @media only screen and (max-width: 500px) {
    font-size: 18px;
    line-height: 20px;
  }
`;
const ChillfulBodySubHeadingImage = styled.img`
  height: 38px;
  margin-right: 15px;
  display: inline;
`;
const ChillfulBodySubHeadingSpan1 = styled.span`
  font-weight: 800;
`;
const ChillfulBodySubHeadingSpan2 = styled.span`
  color: #ff9700;
  font-weight: 800;
`;
const ChillfulMargin50 = styled.div`
  height: 50px;
`;
const ChillfulActivityListStyle2 = styled.div`
  margin-left: 150px;
  margin-right: 150px;
  @media only screen and (max-width: 1300px) {
    margin-left: 50px;
    margin-right: 50px;
  }
  @media only screen and (max-width: 900px) {
    margin-left: 20px;
    margin-right: 20px;
  }
`;
const ChillfulActivityListStyle2Arrow = styled.img`
  height: 16px;
  vertical-align: middle;
  margin-bottom: 6px;
  margin-top: 4px;
  margin-left: 5px;
`;
const ChillfulActivityListStyle2Title = styled.span`
  font-size: 18px;
`;
const ChillfulColWidth50 = styled.col`
  width: 50%;
  @media only screen and (max-width: 800px) {
    width: 50%;
  }
  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`;
const ChillfulColWidth25Long2 = styled.col`
  width: 25%;
  @media only screen and (max-width: 800px) {
    width: 50%;
  }
  @media only screen and (max-width: 600px) {
    width: 0%;
  }
`;
const ChillfulColWidth25Long = styled.col`
  width: 25%;
  @media only screen and (max-width: 1200px) {
    display: none;
  }
`;
*/
const ChillfulActivityListIcon = styled.img`
  height: 12px;
  margin-right: 2px;
  margin-bottom: -1px;
  display: inline;
`;
/*
const ChillfulActivityListStyle2Container = styled.div`
  position: relative;
  width: 100%;
  padding-top: calc(80% - 36px);
`;
const ChillfulActivityListStyle2ContainerTd = styled.td`
  display: none;
  @media only screen and (max-width: 800px) {
    display: block;
  }
  @media only screen and (max-width: 600px) {
    display: none;
  }
`;
const ChillfulActivityListStyle2ContainerDiv1 = styled.div`
  cursor: pointer;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  box-shadow: 0px 3px 12px 2px rgba(0, 0, 0, 0.05);
  @media only screen and (max-width: 800px) {
    margin-right: 5px;
  }
`;
const ChillfulActivityListStyle2ContainerDiv2 = styled.div`
  cursor: pointer;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  box-shadow: 0px 3px 12px 2px rgba(0, 0, 0, 0.05);
  @media only screen and (max-width: 800px) {
    margin-left: 5px;
  }
`;
const ChillfulActivityListStyle2TdLong = styled.td`
  @media only screen and (max-width: 1200px) {
    display: none;
  }
`;
const ChillfulActivityListStyle2TdLong2 = styled.td`
  @media only screen and (max-width: 800px) {
    display: none;
  }
`;
const ChillfulActivityListStyle2ImageCover = styled.div`
  background-size: cover;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;
const ChillfulActivityListStyle2ClassBottom = styled.div`
  height: 118px;
  background-color: white;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  padding: 15px 20px 0px 20px;
  color: black;
  @media only screen and (max-width: 800px) {
    height: 100px;
  }
`;
*/
const ChillfulActivityListStyle2ClassBottomText1 = styled.div`
  font-size: 14px;
  color: #ff9700;
  display: inline;
  margin-left: 3px;
  font-weight: 700;
  text-transform: uppercase;
`;
/*
const ChillfulActivityListStyle2ClassBottomText2 = styled.div`
  font-size: 28px;
  line-height: 32px;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  @media only screen and (max-width: 800px) {
    font-size: 20px;
    line-height: 24px;
  }
`;
const ChillfulActivityListStyle2ClassBottomSmallText2 = styled.div`
  font-size: 20px;
  line-height: 22px;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;
const ChillfulActivityListStyle2ClassBottomText3 = styled.div`
  font-size: 18px;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  @media only screen and (max-width: 800px) {
    font-size: 14px;
  }
`;
const ChillfulActivityListStyle2ClassBottomText4 = styled.div`
  font-size: 16px;
  margin-top: 4px;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-transform: capitalize;
  @media only screen and (max-width: 800px) {
    font-size: 14px;
  }
`;
const ChillfulActivityListStyle2Container2 = styled.div`
  position: relative;
  width: 100%;
  padding-top: calc(80% - 72px);
  @media only screen and (max-width: 1200px) {
    padding-top: calc(80% - 77px);
  }
`;
const ChillfulActivityListStyle2Container3 = styled.div`
  position: relative;
  width: 100%;
  padding-top: calc(40% - 72px);
`;
const ChillfulActivityListStyle2ClassBottom2 = styled.div`
  transition: height 0.5s;
  height: 100px;
  background-color: white;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  padding: 10px 20px 0px 20px;
  color: black;
  @media only screen and (max-width: 1200px) {
    height: 118px;
    padding: 15px 20px 0px 20px;
  }
`;
const ChillfulActivityListStyle2Small1 = styled.div`
  margin-left: 24px;
  cursor: pointer;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  box-shadow: 0px 3px 12px 2px rgba(0, 0, 0, 0.05);
`;
const ChillfulActivityListStyle2Small2 = styled.div`
  margin-left: 24px;
  margin-top: 24px;
  cursor: pointer;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  box-shadow: 0px 3px 12px 2px rgba(0, 0, 0, 0.05);
  @media only screen and (max-width: 1200px) {
    display: none;
  }
`;
const ChillfulActivityListStyle2Small3 = styled.div`
  margin-left: 24px;
  margin-top: 24px;
  display: none;
  cursor: pointer;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  box-shadow: 0px 3px 12px 2px rgba(0, 0, 0, 0.05);
  @media only screen and (max-width: 1200px) {
    display: inline-block;
  }
  @media only screen and (max-width: 800px) {
    display: none;
  }
`;
*/
const ChillfulActivityListStyle1 = styled.div`
  padding-top: 70px;
`;
const ChillfulActivityListStyle1Title = styled.span`
  font-size: 18px;
`;
const ChillfulActivityListStyle1TitleDiv = styled.div`
  margin-left: 150px;
  margin-right: 150px;
  @media only screen and (max-width: 1024px) {
    margin-left: 100px;
    margin-right: 100px;
  }
  @media only screen and (max-width: 900px) {
    margin-left: 70px;
    margin-right: 70px;
  }
  @media only screen and (max-width: 768px) {
    margin-left: 50px;
    margin-right: 50px;
  }
  @media only screen and (max-width: 480px) {
    margin-left: 20px;
    margin-right: 20px;
  }
`;
const AmbientMusicMarginDiv = styled.div`
  height: 60px;
  @media only screen and (max-width: 1024px) {
    height: 40px;
  }
  @media only screen and (max-width: 768px) {
    height: 30px;
  }
  @media only screen and (max-width: 480px) {
    height: 20px;
  }
`;
const AmbientMusicTitle = styled.h1`
  color: #333;
  font-weight: 600;
  font-size: 30px;
  margin-left: 0px;
  @media only screen and (max-width: 480px) {
    font-size: 24px;
  }
`;
/*
const ChillfulActivityListStyle1Margin = styled.div`
  width: 100px;
  @media only screen and (max-width: 1300px) {
    width: 50px;
  }
  @media only screen and (max-width: 900px) {
    width: 20px;
  }
`;
const ChillfulActivityListStyle1Margin2 = styled.div`
  width: 15px;
`;
const ChillfulActivityListStyle1Td = styled.td`
  min-width: 350px;
  max-width: 350px;
`;
const ChillfulActivityListStyle1Table = styled.table`
  width: 100%;
`;

const DemoDiv = styled.div`
  margin-left: 150px;
  margin-right: 150px;
  padding-top: 140px;
  margin-bottom: 80px;
  @media only screen and (max-width: 1200px) {
    margin-left: 100px;
    margin-right: 100px;
  }
  @media only screen and (max-width: 1024px) {
    margin-left: 50px;
    margin-right: 50px;
  }
  @media only screen and (max-width: 768px) {
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 40px;
  }
  @media only screen and (max-width: 480px) {
    margin-left: 20px;
    margin-right: 20px;
  }
`;

const DemoBtn = styled.div`
  border-radius: 10px;
  background-color: #ff9700;
  color: white;
  padding: 5px;
  width: 100px;
  text-align: center;
  display: inline-block;
  margin: 5px 5px;
  cursor: pointer;
  @media only screen and (max-width: 1200px) {
    padding: 5px;
    font-size: 14px;
  }
  @media only screen and (max-width: 1024px) {
    padding: 5px;
    font-size: 14px;
    width: 80px;
  }
`;

const DemoLineBreak = styled.br`
  display: none;
  @media only screen and (max-width: 768px) {
    display: block;
  }
`;

const DemoTitle = styled.h1`
  font-size: 60px;
  font-weight: 700;
  color: #444;
  text-align: center;
  margin-bottom: 25px;
  @media only screen and (max-width: 1200px) {
    font-size: 50px;
  }
  @media only screen and (max-width: 768px) {
    font-size: 40px;
  }
  @media only screen and (max-width: 480px) {
    font-size: 30px;
  }
`;
*/
const MeditationPopupTable = styled.table`
  width: 1000px;
  margin: 0px auto;
  border-spacing: 0px;
  border-collapse: 0px;
  @media only screen and (max-width: 1200px) {
    width: 900px;
  }
  @media only screen and (max-width: 1024px) {
    width: 700px;
  }
  @media only screen and (max-width: 768px) {
    width: calc(100% - 40px);
  }
  @media only screen and (max-width: 480px) {
    width: calc(100% - 40px);
  }
`;

const AppDemoTitle = styled.h1`
  font-size: 60px;
  white-space: pre-line;
  @media only screen and (max-width: 1200px) {
    font-size: 50px;
  }
  @media only screen and (max-width: 768px) {
    font-size: 40px;
  }
  @media only screen and (max-width: 480px) {
    font-size: 30px;
  }
`;

const AppDemoDiv = styled.div`
  margin-left: 100px;
  margin-right: 100px;
  margin-bottom: -60px;
  @media only screen and (max-width: 1200px) {
    margin-left: 80px;
    margin-right: 80px;
  }
  @media only screen and (max-width: 1024px) {
    margin-left: 20px;
    margin-right: 20px;
  }
  @media only screen and (max-width: 768px) {
    margin-left: 20px;
    margin-right: 20px;
  }
  @media only screen and (max-width: 480px) {
    margin-left: 20px;
    margin-right: 20px;
  }
`;

const AppDemoImage = styled.img`
  width: 250px !important;
  margin-left: 35px;
  margin-right: 35px;
  border-radius: 27px;
  @media only screen and (max-width: 1200px) {
    width: 200px !important;
    margin-left: 30px;
    margin-right: 30px;
  }
  @media only screen and (max-width: 1024px) {
    width: 200px !important;
    margin-left: 20px;
    margin-right: 20px;
  }
  @media only screen and (max-width: 768px) {
    width: 172px !important;
    margin-left: 20px;
    margin-right: 20px;
  }
  @media only screen and (max-width: 480px) {
    width: 150px !important;
    margin-left: 10px;
    margin-right: 10px;
  }
`;

const AppDemoImage4 = styled.img`
  width: 250px !important;
  margin-left: 35px;
  margin-right: 35px;
  border-radius: 27px;
  @media only screen and (max-width: 1200px) {
    width: 200px !important;
    margin-left: 30px;
    margin-right: 30px;
  }
  @media only screen and (max-width: 1024px) {
    width: 200px !important;
    margin-left: 20px;
    margin-right: 20px;
  }
  @media only screen and (max-width: 768px) {
    width: 172px !important;
    margin-left: 20px;
    margin-right: 20px;
  }
  @media only screen and (max-width: 480px) {
    width: 200px !important;
    margin-left: 10px;
    margin-right: 10px;
  }
`;

const AppDemoImage3 = styled.img`
  width: 250px !important;
  margin-left: 35px;
  margin-right: 35px;
  border-radius: 27px;
  @media only screen and (max-width: 1200px) {
    width: 200px !important;
    margin-left: 30px;
    margin-right: 30px;
  }
  @media only screen and (max-width: 1024px) {
    width: 200px !important;
    margin-left: 20px;
    margin-right: 20px;
  }
  @media only screen and (max-width: 768px) {
    width: 172px !important;
    margin-left: 20px;
    margin-right: 20px;
  }
  @media only screen and (max-width: 480px) {
    display: none !important;
  }
`;

const AppDemoImage2 = styled.img`
  width: 250px !important;
  margin-left: 35px;
  margin-right: 35px;
  border-radius: 27px;
  @media only screen and (max-width: 1200px) {
    width: 200px !important;
    margin-left: 30px;
    margin-right: 30px;
  }
  @media only screen and (max-width: 1024px) {
    width: 200px !important;
    margin-left: 20px;
    margin-right: 20px;
  }
  @media only screen and (max-width: 768px) {
    display: none !important;
    width: 200px;
  }
  @media only screen and (max-width: 480px) {
    display: none !important;
    width: 200px;
  }
`;

const BannerBtn = styled.button`
  padding: 20px 35px;
  color: white;
  border-radius: 50px;
  border-style: none;
  background-color: #ff9700;
  font-weight: 700;
  font-size: 16px;
  box-shadow: 0px 3px 12px 2px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  margin-top: 20px;
  @media only screen and (max-width: 1024px) {
    margin-top: 20px;
    font-size: 16px;
    padding: 20px 35px;
  }
  @media only screen and (max-width: 768px) {
    margin-top: 0px;
    font-size: 16px;
    padding: 12px 20px;
  }
  @media only screen and (max-width: 480px) {
    font-size: 14px;
    padding: 10px 16px;
  }
`;

const MindfulDivHeader = styled.h1`
  text-align: center;
  font-size: 45px;
  margin-bottom: 10px;
  margin-top: 160px;
  @media only screen and (max-width: 1024px) {
    font-size: 40px;
  }
  @media only screen and (max-width: 768px) {
    font-size: 40px;
  }
  @media only screen and (max-width: 480px) {
    font-size: 30px;
  }
`;

const MindfulDivSubHeader = styled.p`
  text-align: center;
  font-size: 24px;
  margin-bottom: -50px;
  margin-left: 15vw;
  margin-right: 15vw;
  @media only screen and (max-width: 1024px) {
    font-size: 20px;
    margin-bottom: -70px;
  }
  @media only screen and (max-width: 768px) {
    font-size: 18px;
    margin-left: 20px;
    margin-right: 20px;
  }
  @media only screen and (max-width: 480px) {
    font-size: 16px;
  }
`;
/*
const TopHeaderTop = styled.h1`
  font-size: 60px;
  color: #444;
  @media only screen and (max-width: 1200px) {
    font-size: 50px;
  }
  @media only screen and (max-width: 980px) {
    font-size: 45px;
  }
  @media only screen and (max-width: 768px) {
    font-size: 40px;
  }
  @media only screen and (max-width: 480px) {
    font-size: 40px;
  }
`;

const TopHeader = styled.h1`
  font-size: 90px;
  line-height: 90px;
  color: #444;
  white-space: pre-line;
  @media only screen and (max-width: 1200px) {
    font-size: 80px;
    line-height: 80px;
  }
  @media only screen and (max-width: 980px) {
    font-size: 65px;
    line-height: 65px;
  }
  @media only screen and (max-width: 768px) {
    font-size: 50px;
    line-height: 50px;
  }
  @media only screen and (max-width: 480px) {
    font-size: 50px;
    line-height: 50px;
  }
`;

const TopPara = styled.p`
  margin-top: 20px;
  font-size: 24px;
  width: 70%;
  @media only screen and (max-width: 1200px) {
    font-size: 20px;
  }
  @media only screen and (max-width: 768px) {
    font-size: 16px;
  }
  @media only screen and (max-width: 480px) {
    font-size: 16px;
    width: 90%;
  }
`;

const TopDiv = styled.div`
  background-color: #f4f2ec;
  margin-bottom: -1px;
  text-align: left;
`;

const TopDivContain = styled.div`
  width: 70%;
  @media only screen and (max-width: 1200px) {
  }
  @media only screen and (max-width: 768px) {
    width: 60%;
  }
  @media only screen and (max-width: 650px) {
    width: 100%;
  }
  @media only screen and (max-width: 480px) {
    width: 100%;
  }
`;
*/
const TopImageDiv = styled.div`
  height: 40px;
  width: 1000px;
  boxshadow: 5px 5px 10px 2px rgba(0, 0, 0, 0.1);
  @media only screen and (max-width: 1200px) {
    height: 40px;
    width: 1000px;
  }
  @media only screen and (max-width: 980px) {
    height: 320px;
    width: 800px;
  }
  @media only screen and (max-width: 768px) {
    height: 100%;
    width: 100%;
  }
  @media only screen and (max-width: 650px) {
    display: none;
  }
`;

const TopImageDiv2 = styled.div`
  display: none;
  text-align: left;
  margin-left: 50px;
  margin-right: 50px;
  margin-top: -50px;
  @media only screen and (max-width: 650px) {
    display: block;
  }
  @media only screen and (max-width: 480px) {
    margin-left: 20px;
    margin-right: 20px;
  }
`;

const TopImage = styled.img`
  height: 400px;
  @media only screen and (max-width: 1200px) {
    height: 400px;
  }
  @media only screen and (max-width: 980px) {
    height: 320px;
  }
  @media only screen and (max-width: 768px) {
    height: 100%;
  }
`;

const Div50 = styled.div`
  height: 50px;
  @media only screen and (max-width: 1200px) {
    height: 0px;
  }
  @media only screen and (max-width: 980px) {
    height: 50px;
  }
  @media only screen and (max-width: 768px) {
    height: 20px;
  }
  @media only screen and (max-width: 680px) {
    height: 50px;
  }
`;

function Meditation() {
  const { t, i18n } = useTranslation();

  const [chillfulBannerTitle, setChillfulBannerTitle] = useState('');
  const [chillfulBannerDescription, setChillfulBannerDescription] = useState('');

  const [chillfulActivityTitle, setChillfulActivityTitle] = useState('');
  const [chillfulActivityDescription, setChillfulActivityDescription] = useState('');
  const [chillfulActivityCategory, setChillfulActivityCategory] = useState('');
  const [chillfulActivitySubCategory, setChillfulActivitySubCategory] = useState('');

  const [guidedMeditationTitle1, setGuidedMeditationTitle1] = useState('');
  const [guidedMeditationDescription1, setGuidedMeditationDescription1] = useState('');
  const [guidedMeditationCategory1, setGuidedMeditationCategory1] = useState('');
  const [guidedMeditationSubCategory1, setGuidedMeditationSubCategory1] = useState('');
  const [guidedMeditationImagesLarge1, setGuidedMeditationImagesLarge1] = useState('');
  const [guidedMeditationImagesSmall1, setGuidedMeditationImagesSmall1] = useState('');

  const [guidedMeditationTitle2, setGuidedMeditationTitle2] = useState('');
  const [guidedMeditationDescription2, setGuidedMeditationDescription2] = useState('');
  const [guidedMeditationCategory2, setGuidedMeditationCategory2] = useState('');
  const [guidedMeditationSubCategory2, setGuidedMeditationSubCategory2] = useState('');
  const [guidedMeditationImagesLarge2, setGuidedMeditationImagesLarge2] = useState('');
  const [guidedMeditationImagesSmall2, setGuidedMeditationImagesSmall2] = useState('');

  const [guidedMeditationTitle3, setGuidedMeditationTitle3] = useState('');
  const [guidedMeditationDescription3, setGuidedMeditationDescription3] = useState('');
  const [guidedMeditationCategory3, setGuidedMeditationCategory3] = useState('');
  const [guidedMeditationSubCategory3, setGuidedMeditationSubCategory3] = useState('');
  const [guidedMeditationImagesLarge3, setGuidedMeditationImagesLarge3] = useState('');
  const [guidedMeditationImagesSmall3, setGuidedMeditationImagesSmall3] = useState('');

  const [guidedMeditationTitle4, setGuidedMeditationTitle4] = useState('');
  const [guidedMeditationDescription4, setGuidedMeditationDescription4] = useState('');
  const [guidedMeditationCategory4, setGuidedMeditationCategory4] = useState('');
  const [guidedMeditationSubCategory4, setGuidedMeditationSubCategory4] = useState('');
  const [guidedMeditationImagesLarge4, setGuidedMeditationImagesLarge4] = useState('');
  const [guidedMeditationImagesSmall4, setGuidedMeditationImagesSmall4] = useState('');

  const [guidedMeditationPopup, setGuidedMeditationPopup] = useState([]);

  const [isPopup, setIsPopup] = useState(false);

  const chillfulActivityCategoryId = '';

  useEffect(() => {
    // to get the information for banner
    db.collection('chillfulActivities')
      .doc('12DxUVuZ8qwC3xLvbv16')
      .get()
      .then((doc) => {
        const title = [];
        const description = [];
        const data = doc.data();
        setChillfulBannerTitle(data['title']);
        setChillfulBannerDescription(data['description']);
      });

    // to get the information for activity
    db.collection('chillfulActivities')
      .doc('12DxUVuZ8qwC3xLvbv16')
      .get()
      .then((doc) => {
        const data = doc.data();
        setChillfulActivityTitle(data['title']);
        setChillfulActivityDescription(data['description']);
        setChillfulActivityCategory(data['category']);
        setChillfulActivitySubCategory(data['subCategory']);
      });

    db.collection('mindfulGuidedMeditation')
      .doc('DhvxuE08qwDG0gD0vdf5')
      .get()
      .then((doc) => {
        const data = doc.data();
        setGuidedMeditationTitle1(data['title']);
        setGuidedMeditationDescription1(data['description']);
        setGuidedMeditationCategory1(data['category']);
        setGuidedMeditationSubCategory1(data['subCategory']);
        setGuidedMeditationImagesLarge1(data['imagesLarge']);
        setGuidedMeditationImagesSmall1(data['imagesSmall']);
      });

    db.collection('mindfulGuidedMeditation')
      .doc('q8SfxV4oyuVCf0aUnz3i')
      .get()
      .then((doc) => {
        const data = doc.data();
        setGuidedMeditationTitle2(data['title']);
        setGuidedMeditationDescription2(data['description']);
        setGuidedMeditationCategory2(data['category']);
        setGuidedMeditationSubCategory2(data['subCategory']);
        setGuidedMeditationImagesLarge2(data['imagesLarge']);
        setGuidedMeditationImagesSmall2(data['imagesSmall']);
      });

    db.collection('mindfulGuidedMeditation')
      .doc('nKUfOSSkQeTFHk8uXVTa')
      .get()
      .then((doc) => {
        const data = doc.data();
        setGuidedMeditationTitle3(data['title']);
        setGuidedMeditationDescription3(data['description']);
        setGuidedMeditationCategory3(data['category']);
        setGuidedMeditationSubCategory3(data['subCategory']);
        setGuidedMeditationImagesLarge3(data['imagesLarge']);
        setGuidedMeditationImagesSmall3(data['imagesSmall']);
      });

    db.collection('mindfulGuidedMeditation')
      .doc('ezFCn8PgiHBClil3rFqz')
      .get()
      .then((doc) => {
        const data = doc.data();
        setGuidedMeditationTitle4(data['title']);
        setGuidedMeditationDescription4(data['description']);
        setGuidedMeditationCategory4(data['category']);
        setGuidedMeditationSubCategory4(data['subCategory']);
        setGuidedMeditationImagesLarge4(data['imagesLarge']);
        setGuidedMeditationImagesSmall4(data['imagesSmall']);
      });
  }, []);

  function showMeditation(meditation_id) {
    $('#playVideoDiv').css('display', 'block');
    $('body').css('overflow', 'hidden');
    db.collection('mindfulGuidedMeditation')
      .doc(meditation_id)
      .get()
      .then((doc) => {
        setGuidedMeditationPopup((guidedMeditationPopup) => [
          {
            audio: doc.data()['audio'],
            title: doc.data()['title'],
            category: doc.data()['category'],
            description: doc.data()['description'],
          },
        ]);
      });
  }

  useEffect(() => {
    $('#hideVideoBtn').click(function () {
      $('#playVideoDiv').css('display', 'none');
      $('body').css('overflow', 'scroll');
    });
  }, []);
  /*
    function toApp() {
      window.location.href = '/app/';
    }
  */
  const ref = useRef();
  const openTooltip = () => ref.current.open();

  function toDownloadAppIOS() {
    window.location.href = 'https://apps.apple.com/hk/app/vivablee/id1551773278';
  }
  function toDownloadAppAndroid() {
    window.location.href = 'https://play.google.com/store/apps/details?id=com.vivableeraw';
  }

  return (
    <div>
      <div style={{ width: '100%' }}>
        <PopupModal Ref={ref} />
        <div
          id="playVideoDiv"
          style={{
            display: 'none',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            position: 'fixed',
            zIndex: '200',
          }}
        >
          <div id="hideVideoBtn" style={{ display: 'table', margin: '80px auto 50px auto' }}>
            <p
              style={{
                color: '#555',
                backgroundColor: '#fafafa',
                padding: '5px 14px 6px 14px',
                borderRadius: '50px',
                fontSize: '24px',
                cursor: 'pointer',
              }}
            >
              ⨉
          </p>
          </div>

          <MeditationPopupTable cellspacing="0" cellpadding="0">
            <colgroup>
              <col style={{ width: '50%' }} />
            </colgroup>
            <tbody>
              <tr>
                <td>
                  <div
                    style={{ borderRadius: '20px', width: 'calc( 100% )', backgroundColor: 'white' }}
                  >
                    <div style={{ padding: '50px' }}>
                      <ChillfulActivityListIcon
                        src={require('../images/general/mindful_icon_original.png')}
                      ></ChillfulActivityListIcon>
                      {guidedMeditationPopup.map((data) => (
                        <ChillfulActivityListStyle2ClassBottomText1>
                          {data.category}
                        </ChillfulActivityListStyle2ClassBottomText1>
                      ))}
                      {guidedMeditationPopup.map((data) => (
                        <p style={{ fontSize: '28px', fontWeight: '600', marginTop: '3px' }}>
                          {data.title}
                        </p>
                      ))}
                      {guidedMeditationPopup.map((data) => (
                        <BannerAudio style={{ width: '90%' }} controls>
                          <source src={data.audio} type="audio/ogg" />
                          <source src={data.audio} type="audio/mpeg" />
                        </BannerAudio>
                      ))}
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </MeditationPopupTable>
        </div>

        <Menu page={'meditation'} />

        {/* Be Chillful top banner */}
        <ChillfulBanner>
          <ChillfulMargin120 />

          <ChillfulBannerText>
            <ChillfulBannerIcon
              style={{ marginBottom: '-3px' }}
              src={require('../images/general/mindful_icon_original.png')}
            ></ChillfulBannerIcon>
            <ChillfulBannerText1>{t('meditationBannerCategory')}</ChillfulBannerText1>
            <ChillfulBannerText2>{t('meditationBannerTitle')}</ChillfulBannerText2>
            <ChillfulBannerText3>{t('meditationBannerDescription')}</ChillfulBannerText3>
            <ChillfulBannerText4>
              {t('meditationBannerGuidedMeditation')} ● {t('meditationBannerTime')}
            </ChillfulBannerText4>
            <BannerAudio controls>
              <source
                src={
                  'https://firebasestorage.googleapis.com/v0/b/vivablee-dev.appspot.com/o/journey%2FDemo.mp3?alt=media&token=0988845b-70e3-458e-a1c2-e4111aafc1c6'
                }
                type="audio/ogg"
              />
              <source
                src={
                  'https://firebasestorage.googleapis.com/v0/b/vivablee-dev.appspot.com/o/journey%2FDemo.mp3?alt=media&token=0988845b-70e3-458e-a1c2-e4111aafc1c6'
                }
                type="audio/mpeg"
              />
            </BannerAudio>
          </ChillfulBannerText>
        </ChillfulBanner>

        {/*
      <MeditationSearchBox />
      */}

        <div id="demoMeditationScroll" style={{ marginTop: '80px' }} />

        <MindfulDivHeader>遊戲化的冥想旅程</MindfulDivHeader>
        <MindfulDivSubHeader>
          透過靜觀旅程，體驗不同的冥想練習，並可以在建立恆常練習的習慣的同時，獲得不同的獎賞。
      </MindfulDivSubHeader>

        <center>
          <AppDemoDiv>
            <div style={{ marginTop: '100px' }}>
              <AppDemoImage
                src={require('../images/mindful/gamification_1_zh_HK.png')}
              ></AppDemoImage>
              <AppDemoImage
                src={require('../images/mindful/gamification_2_zh_HK.png')}
              ></AppDemoImage>
              <AppDemoImage2
                src={require('../images/mindful/gamification_3_zh_HK.png')}
              ></AppDemoImage2>
            </div>
            <div style={{ marginTop: '80px', position: 'relative' }}>
              <div
                style={{
                  left: '50%',
                  marginRight: '-50%',
                  position: 'absolute',
                  transform: 'translate(-50%, -50%)',
                }}
              >
                <BannerBtn onClick={isDesktop ? openTooltip : (isIOS ? toDownloadAppIOS : toDownloadAppAndroid)}>{t('homeDownloadAppDownloadBtn')}</BannerBtn>
              </div>
            </div>
          </AppDemoDiv>
        </center>

        <ChillfulBody>
          <svg
            style={{ marginTop: '100px', marginBottom: '-7px' }}
            width="100%"
            //height="auto"
            viewBox="0 0 3840 260"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Path</title>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <path
                d="M-9.09494702e-13,0.5 L-9.09494702e-13,259.5 C543.289571,86.8333333 1184.99624,0.5 1925.12,0.5 C2499.39149,0.5 3137.68482,86.8333333 3840,259.5 L3840,0.5 L-9.09494702e-13,0.5 Z"
                id="Path"
                fill="#f3f3f3"
                transform="translate(1920.000000, 130.000000) scale(1, -1) translate(-1920.000000, -130.000000) "
              ></path>
            </g>
          </svg>

          <ChillfulActivityListStyle1 style={{ backgroundColor: '#f3f3f3' }}>
            <ChillfulActivityListStyle1TitleDiv>
              <AmbientMusicTitle>
                多樣化的廣東話冥想練習庫，
              <br />
              圍繞日常生活6大範疇，時刻深化你的靜觀體驗。
            </AmbientMusicTitle>
            </ChillfulActivityListStyle1TitleDiv>
            <AmbientMusicMarginDiv></AmbientMusicMarginDiv>

            <Carousel
              style={{ zIndex: 100 }}
              emulateTouch={true}
              interval={3000}
              autoPlay={true}
              showThumbs={false}
              showStatus={false}
              infiniteLoop={true}
              renderIndicator={(onClickHandler, isSelected, index, label) => {
                return (
                  // this is styled component.
                  <div
                    selected={isSelected}
                    onClick={onClickHandler}
                    style={{
                      marginLeft: 8,
                      marginRight: 8,
                      cursor: 'pointer',
                      height: 10,
                      width: 10,
                      borderRadius: 20,
                      backgroundColor: isSelected ? '#FF9700' : 'white',
                      display: 'inline-block',
                    }}
                  ></div>
                );
              }}
            >
              <div>
                <Div50></Div50>
                <center>
                  <TopImageDiv>
                    <TopImage src={require('../images/mindful/dailyRoutine_zh_HK.png')}></TopImage>
                  </TopImageDiv>
                  <TopImageDiv2>
                    <ChillfulBannerIcon
                      style={{ marginBottom: '-3px', width: 20, height: 20, marginRight: 5 }}
                      src={require('../images/general/mindful_icon_original.png')}
                    ></ChillfulBannerIcon>
                    <span style={{ fontWeight: '600' }}>起床</span>
                    <p style={{ marginBottom: 20 }}>早晨醒來，以冥想開始平靜清新的一天</p>
                    <ChillfulBannerIcon
                      style={{ marginBottom: '-3px', width: 20, height: 20, marginRight: 5 }}
                      src={require('../images/general/mindful_icon_original.png')}
                    ></ChillfulBannerIcon>
                    <span style={{ fontWeight: '600' }}>旅途</span>
                    <p style={{ marginBottom: 20 }}>
                      火車和巴士的旅程已成為你旅鬆的一部分，並為您的工作做好了準備
                  </p>
                    <ChillfulBannerIcon
                      style={{ marginBottom: '-3px', width: 20, height: 20, marginRight: 5 }}
                      src={require('../images/general/mindful_icon_original.png')}
                    ></ChillfulBannerIcon>
                    <span style={{ fontWeight: '600' }}>專注</span>
                    <p style={{ marginBottom: 20 }}>通過短暫的冥想練習，增強自己的注意力</p>
                    <ChillfulBannerIcon
                      style={{ marginBottom: '-3px', width: 20, height: 20, marginRight: 5 }}
                      src={require('../images/general/mindful_icon_original.png')}
                    ></ChillfulBannerIcon>
                    <span style={{ fontWeight: '600' }}>放鬆</span>
                    <p style={{ marginBottom: 20 }}>
                      在工作或上學後的一天，消除壓力，享受靜觀帶來的放鬆感覺
                  </p>
                    <ChillfulBannerIcon
                      style={{ marginBottom: '-3px', width: 20, height: 20, marginRight: 5 }}
                      src={require('../images/general/mindful_icon_original.png')}
                    ></ChillfulBannerIcon>
                    <span style={{ fontWeight: '600' }}>生活</span>
                    <p style={{ marginBottom: 20 }}>
                      與同伴進行冥想練習以達至感受當下的生活方式(例如，靜觀飲食，減輕壓力的系列)
                  </p>
                    <ChillfulBannerIcon
                      style={{ marginBottom: '-3px', width: 20, height: 20, marginRight: 5 }}
                      src={require('../images/general/mindful_icon_original.png')}
                    ></ChillfulBannerIcon>
                    <span style={{ fontWeight: '600' }}>睡眠</span>
                    <p style={{ marginBottom: 20 }}>
                      失眠或想改善睡眠質素？冥想及睡眠音樂肯定會有所幫助
                  </p>
                    <div style={{ height: 30 }}></div>
                  </TopImageDiv2>
                </center>
              </div>
              <div>
                <center>
                  <AppDemoDiv style={{ marginTop: -100 }}>
                    <div style={{ marginTop: '100px' }}>
                      <AppDemoImage4
                        src={require('../images/mindful/dailyRoutine_1_zh_HK.png')}
                      ></AppDemoImage4>
                      <AppDemoImage3
                        src={require('../images/mindful/dailyRoutine_2_zh_HK.png')}
                      ></AppDemoImage3>
                      <AppDemoImage2
                        src={require('../images/mindful/dailyRoutine_3_zh_HK.png')}
                      ></AppDemoImage2>
                    </div>
                  </AppDemoDiv>
                  <div style={{ height: 120 }}></div>
                </center>
              </div>
            </Carousel>
          </ChillfulActivityListStyle1>

          <div style={{ height: 100, backgroundColor: '#F3F3F3' }}></div>
        </ChillfulBody>

        <svg
          style={{ backgroundColor: 'white', marginTop: '-80px', zIndex: '3', position: 'relative' }}
          width="100%"
          //height="auto"
          viewBox="0 0 3840 259"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <title>Path</title>
          <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path
              d="M0,0 C539.876237,172.666667 1179.87624,259 1920,259 C2494.27149,259 3134.27149,172.666667 3840,0 L0,0 Z"
              id="Path"
              fill="#f3f3f3"
            ></path>
          </g>
        </svg>

        <Footer />
      </div>
    </div>
  );
}

export default Meditation;
