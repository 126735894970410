import React, { useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components'; // package to define css class
import $ from 'jquery'; // package to run jQuery
//import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PopupModal from '../components/PopupModal';

import {
  isDesktop,
  isIOS
} from "react-device-detect";

// define css class
const MenuDiv = styled.div`
  padding-top: 28px;
  padding-bottom: 12px;
  transition: all 0.5s;
  height: 35px;
  background-color: white;
  position: fixed;
  width: 100%;
  z-index: 100;
`;
const MenuTable = styled.table`
  margin-left: 250px;
  @media only screen and (max-width: 1200px) {
    display: none;
  }
`;
const MenuLogo = styled.img`
  position: absolute;
  height: 30px;
  left: 50px;
  top: 23px;
  cursor: pointer;
  transition: all 0.5s;
  @media only screen and (max-width: 1200px) {
    left: 20px;
  }
`;
const MenuMobileIcon = styled.img`
  position: absolute;
  height: 28px;
  right: 20px;
  top: 27px;
  cursor: pointer;
  transition: all 0.5s;
  height: 25px;
  @media only screen and (min-width: 1201px) {
    display: none;
  }
`;
/*
const MenuIcon = styled.img`
  margin-left: 10px;
  height: 18px;
  cursor: pointer;
`;
*/
const MenuCategory = styled.span`
  color: #333;
  cursor: pointer;
  transition: all 0.5s;
  font-weight: 600;
  transition-property: none;
  font-size: 16px;
`;
const MenuCategory2 = styled.span`
  margin-right: 30px;
  color: #333;
  cursor: pointer;
  transition: all 0.5s;
  font-weight: 600;
  transition-property: none;
  font-size: 16px;
`;
/*
const MenuCategory3 = styled.span`
  margin-right: 0px;
  color: #333;
  cursor: pointer;
  transition: all 0.5s;
  font-weight: 600;
  transition-property: none;
  font-size: 16px;
`;
*/
const MenuMargin = styled.div`
  height: 75px;
`;
const MenuRight = styled.div`
  position: absolute;
  right: 50px;
  top: 0px;
  margin-top: 20px;
  transition: all 0.5s;
  @media only screen and (max-width: 1200px) {
    display: none;
  }
`;
const MenuButton = styled.button`
  padding: 10px 20px;
  color: white;
  border-radius: 50px;
  border-style: none;
  background-color: #ff9700;
  font-weight: 700;
  font-size: 16px;
  box-shadow: 0px 3px 12px 2px rgba(0, 0, 0, 0.05);
  cursor: pointer;
`;
const MenuArticlesLine = styled.div`
  opacity: 0;
  height: 3px;
  background-color: #bbb;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  transition: all 0.3s;
`;
/*
const MenuAboutusLine = styled.div`
  opacity: 0;
  height: 3px;
  background-color: #bbb;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  transition: all 0.3s;
`;
*/
const MenuMeditationLine = styled.div`
  opacity: 0;
  height: 3px;
  background-color: #ff9700;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  transition: all 0.3s;
`;
const MenuActivitiesLine = styled.div`
  opacity: 0;
  height: 3px;
  background-color: #ff585d;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  transition: all 0.3s;
`;
const MenuCorporatesLine = styled.div`
  opacity: 0;
  height: 3px;
  background-color: #81d7d0;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  transition: all 0.3s;
`;
const MenuAtoZLine = styled.div`
  opacity: 0;
  height: 3px;
  background-color: #ff9700;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  transition: all 0.3s;
`;

function Menu({ page, shadow }) {
  const pageType = ['activities', 'meditation', 'articles', 'article', 'atoz', 'atozPage'];

  const { t, i18n } = useTranslation();

  useEffect(() => {

    $('#' + page + '_text').click(function () {
      $('html,body').animate({ scrollTop: 0 }, 'slow');
      return false;
    });

    if (shadow) {
      $('.menu').css('boxShadow', '0px 0px 12px 5px rgba(0,0,0,0.10)');
    }

    if (page === 'article') page = 'articles';

    if (page === 'atozPage') page = 'atoz';

    $('#' + page + '_line').css('opacity', '1');

    if (page !== 'articles') {
      $('#articles_text').hover(
        function () {
          $('#articles_line').css('opacity', '1');
        },
        function () {
          $('#articles_line').css('opacity', '0');
        },
      );
    }

    if (page !== 'meditation') {
      $('#meditation_text').hover(
        function () {
          $('#meditation_line').css('opacity', '1');
        },
        function () {
          $('#meditation_line').css('opacity', '0');
        },
      );
    }

    if (page !== 'activities') {
      $('#activities_text').hover(
        function () {
          $('#activities_line').css('opacity', '1');
        },
        function () {
          $('#activities_line').css('opacity', '0');
        },
      );
    }

    if (page !== 'corporates') {
      $('#corporates_text').hover(
        function () {
          $('#corporates_line').css('opacity', '1');
        },
        function () {
          $('#corporates_line').css('opacity', '0');
        },
      );
    }

    if (page !== 'aboutus') {
      $('#aboutus_text').hover(
        function () {
          $('#aboutus_line').css('opacity', '1');
        },
        function () {
          $('#aboutus_line').css('opacity', '0');
        },
      );
    }

    if (page !== 'atoz') {
      $('#atoz_text').hover(
        function () {
          $('#atoz_line').css('opacity', '1');
        },
        function () {
          $('#atoz_line').css('opacity', '0');
        },
      );
    }
  }, []);

  useEffect(() => {
    $(window).scroll(function () {
      const scroll = $(window).scrollTop();
      if (scroll > 80) {
        $('.menu').css('boxShadow', '0px 0px 12px 5px rgba(0,0,0,0.10)');
      } else {
        if (!shadow) {
          $('.menu').css('boxShadow', 'none');
        }
      }
    });
  });

  function showMobile() {
    $('#mobile_grey').css('height', '100vh');
    $('#mobile_grey_container').css('right', '0px');
  }

  function hideMobile() {
    $('#mobile_grey_container').css('right', '-300px');
    setTimeout(function () {
      $('#mobile_grey').css('height', '0px');
    }, 500);
  }
  /*
    function toMindfulness() {
      window.location.href = '/Articles';
    }
  
    function toDownloadApp() {
      window.location.href = '../app';
    }
  
    function comingSoon2() {
      alert('Coming Soon!');
    }
  */
  function toInstagram() {
    window.location.href = 'https://www.instagram.com/vivablee/';
  }
  /*
    function toInfoEmail() {
      window.location.href = 'mailto:info@vivablee.com';
    }
  
    function toEn() {
      i18n.changeLanguage('en');
    }
  
    function toZhHK() {
      i18n.changeLanguage('zh_HK');
    }
  */
  /*
    function refresh() {
      window.location.reload();
    }
    function toHome() {
      window.location.replace('/Home');
    }
    function toActivities() {
      window.location.href = '/Activities';
    }
    function toMeditation() {
      window.location.href = '/Meditation';
    }
    function toCorporates() {
      window.location.href = '/Corporates';
    }
    function toMindfulness() {
      window.location.href = '/Articles';
    }
    function toAtoZ() {
      window.location.href = '/AtoZ';
    }
  */
  const ref = useRef();
  const openTooltip = () => ref.current.open();

  function toDownloadAppIOS() {
    window.location.href = 'https://apps.apple.com/hk/app/vivablee/id1551773278';
  }
  function toDownloadAppAndroid() {
    window.location.href = 'https://play.google.com/store/apps/details?id=com.vivableeraw';
  }

  // menu content
  return (
    <div>
      <PopupModal Ref={ref} />
      <div
        id="mobile_grey"
        style={{
          height: '0px',
          width: '100vw',
          backgroundColor: 'rgba(0, 0, 0, 0.3)',
          zIndex: '10000',
          position: 'fixed',
        }}
      >
        <div
          id="mobile_grey_container"
          style={{
            transition: 'all 0.5s',
            backgroundColor: 'white',
            width: '200px',
            height: '100vh',
            right: '-300px',
            position: 'absolute',
            padding: '50px',
            fontSize: '18px',
          }}
        >
          <p
            onClick={hideMobile}
            style={{
              position: 'absolute',
              right: '50px',
              top: '50px',
              zIndex: '10001',
              fontSize: '24px',
              cursor: 'pointer',
              fontWeight: '600',
              color: '#555',
            }}
          >
            ✕
          </p>

          <div style={{ height: '50px' }} />
          <Link to="/" style={{ textDecoration: 'none' }}>
            <MenuCategory
              onClick={hideMobile}
              style={{ fontSize: '18px' }}
              id="home_text_mobile"
              className="category_mobile"
            >
              {t('homePage')}
            </MenuCategory>
          </Link>
          <div style={{ height: '20px' }} />
          <Link to="/Activities" style={{ textDecoration: 'none' }}>
            <MenuCategory
              onClick={hideMobile}
              style={{ fontSize: '18px' }}
              id="articles_text_mobile"
              className="category_mobile"
            >
              {t('menuActivities')}
            </MenuCategory>
          </Link>
          <div style={{ height: '20px' }} />
          <Link to="/Meditation" style={{ textDecoration: 'none' }}>
            <MenuCategory
              onClick={hideMobile}
              style={{ fontSize: '18px' }}
              id="meditation_text_mobile"
              className="category_mobile"
            >
              {t('menuMindful')}
            </MenuCategory>
          </Link>
          <div style={{ height: '20px' }} />
          <Link to="/Corporates" style={{ textDecoration: 'none' }}>
            <MenuCategory
              onClick={hideMobile}
              style={{ fontSize: '18px' }}
              id="activities_text_mobile"
              className="category_mobile"
            >
              {t('menuCorporate')}
            </MenuCategory>
          </Link>
          <div style={{ height: '20px' }} />
          <Link to="/Articles" style={{ textDecoration: 'none' }}>
            <MenuCategory
              onClick={hideMobile}
              style={{ fontSize: '18px' }}
              id="activities_text_mobile"
              className="category_mobile"
            >
              {t('menuArticles')}
            </MenuCategory>
          </Link>
          <div style={{ height: '20px' }} />
          <Link to="/AtoZ" style={{ textDecoration: 'none' }}>
            <MenuCategory
              onClick={hideMobile}
              style={{ fontSize: '18px' }}
              id="atoz_text_mobile"  //needed to change
              className="category_mobile"
            >
              {t('menuAtoZ')}
            </MenuCategory>
          </Link>
        </div>
      </div>

      <div>
        <MenuDiv className="menu">
          <Link to="/">
            <MenuLogo
              className="menuLogo"
              src={require('../images/general/logo_original.png')}
            ></MenuLogo>
          </Link>
          <MenuMobileIcon
            className="menuMobileIcon"
            onClick={showMobile}
            src={require('../images/general/menu_icon_grey.png')}
          ></MenuMobileIcon>

          <MenuTable className="menuTable">
            <tbody>
              <tr>
                <td id="activities">
                  <Link to="/Activities" style={{ textDecoration: 'none' }}>
                    <MenuCategory id="activities_text" className="category">
                      {t('menuActivities')}
                    </MenuCategory>
                  </Link>
                </td>

                <td>
                  <div style={{ width: '30px' }}></div>
                </td>

                <td id="meditation">
                  <Link to="/Meditation" style={{ textDecoration: 'none' }}>
                    <MenuCategory id="meditation_text" className="category">
                      {t('menuMindful')}
                    </MenuCategory>
                  </Link>
                </td>

                <td>
                  <div style={{ width: '30px' }}></div>
                </td>

                <td id="corporates">
                  <Link to="/Corporates" style={{ textDecoration: 'none' }}>
                    <MenuCategory id="corporates_text" className="category">
                      {t('menuCorporate')}
                    </MenuCategory>
                  </Link>
                </td>

                <td>
                  <div style={{ width: '30px' }}></div>
                </td>

                <td id="articles">
                  <Link to="/Articles" style={{ textDecoration: 'none' }}>
                    {/** to="/Articles" */}
                    <MenuCategory id="articles_text" className="category">
                      {/* onClick={toMindfulness} */}
                      {t('menuArticles')}
                    </MenuCategory>
                  </Link>
                </td>

                <td>
                  <div style={{ width: '30px' }}></div>
                </td>

                {/* link to AtoZ page with id = atoz */}
                <td id="atoz">
                  <Link to="/AtoZ" style={{ textDecoration: 'none' }}>
                    <MenuCategory id="atoz_text" className="category">
                      {t('menuAtoZ')}
                    </MenuCategory>
                  </Link>
                </td>

                <td>
                  <div style={{ width: '30px' }}></div>
                </td>
              </tr>

              <tr>
                <td>
                  <div style={{ height: '16px' }}></div>
                </td>
              </tr>

              <tr>
                <td>
                  <MenuActivitiesLine id="activities_line"></MenuActivitiesLine>
                </td>
                <td></td>
                <td>
                  <MenuMeditationLine id="meditation_line"></MenuMeditationLine>
                </td>
                <td></td>
                <td>
                  <MenuCorporatesLine id="corporates_line"></MenuCorporatesLine>
                </td>
                <td></td>
                <td>
                  <MenuArticlesLine id="articles_line"></MenuArticlesLine>
                </td>
                <td></td>
                <td>
                  <MenuAtoZLine id="atoz_line"></MenuAtoZLine>
                </td>

              </tr>
            </tbody>
          </MenuTable>

          <MenuRight className="menuRight">
            <MenuCategory2 onClick={toInstagram} id="articles_text" className="category">
              Instagram
          </MenuCategory2>
            {/*
          <MenuCategory3 style={{ fontSize:15, color:i18n.language == 'zh_HK' ? '#FF9700' : 'black' }} onClick={ toZhHK } id="articles_text" className="category">中文</MenuCategory3>
          <MenuCategory3 id="articles_text" className="category"> / </MenuCategory3>
          <MenuCategory2 style={{ color:i18n.language == 'en' ? '#FF9700' : 'black' }} onClick={ toEn } id="articles_text" className="category">Eng</MenuCategory2>
          */}
            <MenuButton onClick={isDesktop ? openTooltip : (isIOS ? toDownloadAppIOS : toDownloadAppAndroid)}>{t('menuDownloadApp')}</MenuButton>
          </MenuRight>
        </MenuDiv>
      </div>

      <MenuMargin className="menuMargin"></MenuMargin>
    </div>
  );
}

export default Menu;
