import React, { useEffect } from 'react';
//import ActivityCard from '../components/ActivityCard';
//import ActivitySearchBox from '../components/ActivitySearchBox';
import Menu from '../components/Menu';
import Footer from '../components/Footer';
//import Demo from '../components/Demo';
//import BrowseArticles from '../components/BrowseArticles';
//import ArticleBanner from '../components/ArticleBanner';
import styled from 'styled-components'; // package to define css class
import $ from 'jquery'; // package to run jQuery
//import img from '../images/chillful/event_1.jpg';
//import img_article_1 from '../images/articles/article1.jpg';
//import img_article_2 from '../images/articles/article2.jpg';
//import img_article_3 from '../images/articles/article3.jpg';
//import img_article_4 from '../images/articles/article4.jpg';
//import img_event_1 from '../images/chillful/event_1.jpg';
import activity_intro_img_1 from '../images/corporates/activityIntro1.jpg';
import activity_intro_img_2 from '../images/corporates/activityIntro2.jpg';
import activity_intro_img_3 from '../images/corporates/activityIntro3.jpg';
import activity_intro_img_4 from '../images/corporates/activityIntro4.jpg';
import banner_video from '../images/corporates/banner.jpg';
//import pattern_background from '../images/general/pattern_background.jpg';
//import demo1 from '../images/mp3/demo1.mp3';
//import { storage, db } from '../firebase';
//import Slider from 'infinite-react-carousel';
//import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { Container, Row, Col } from 'react-grid-system';
//import { Helmet, HelmetProvider } from "react-helmet-async";

const BannerBtn = styled.button`
  padding: 20px 35px;
  color: white;
  border-radius: 50px;
  border-style: none;
  background-color: #ff9700;
  font-weight: 700;
  font-size: 16px;
  box-shadow: 0px 3px 12px 2px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  margin-top: 30px;
`;
/*
const BannerBtn2 = styled.button`
  padding: 30px 33px;
  color: white;
  border-radius: 50px;
  border-style: none;
  background-color: #ff9700;
  font-weight: 700;
  font-size: 16px;
  box-shadow: 0px 3px 12px 2px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  margin-top: 30px;
`;
const BannerBtn3 = styled.button`
  padding: 0px 7px 1px 7px;
  color: white;
  border-radius: 50px;
  border-style: none;
  background-color: #ff9700;
  font-weight: 700;
  font-size: 16px;
  box-shadow: 0px 3px 12px 2px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  margin-left: 20px;
`;
*/
const BannerVideo = styled.div`
  background-image: url(${banner_video});
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 550px;
  margin-top: -15px;
  border-radius: 10px;
`;
/*
const PatternBackground = styled.div`
  background-image: url(${pattern_background});
  background-size: cover;
  width: 100%;
  height: 500px;
  margin-top: -15px;
  border-radius: 10px;
`;
const ArticleCardDivGrey = styled.div`
  width: calc(50% - 13px);
  height: 300px;
  border-radius: 15px;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 10px;
  position: absolute;
  margin-top: -310px;
  background-color: rgba(0, 0, 0, 0.1);
  cursor: pointer;
`;
*/
const ArticleVideoDiv = styled.div`
  padding: 70px 150px 100px 150px;
  @media only screen and (max-width: 1200px) {
    padding: 70px 100px 100px 100px;
  }
  @media only screen and (max-width: 1024px) {
    padding: 70px 60px 100px 60px;
  }
  @media only screen and (max-width: 900px) {
    padding: 70px 60px 50px 60px;
  }
  @media only screen and (max-width: 768px) {
    padding: 70px 20px 50px 20px;
  }
  @media only screen and (max-width: 480px) {
    padding: 70px 20px 50px 20px;
  }
`;
/*
const ArticleVideoOverlayDiv = styled.div`
  position: absolute;
  width: calc(50% - 153px);
  height: 550px;
  border-radius: 10px;
  margin-top: -550px;
  background-color: rgba(0, 0, 0, 0.4);
  @media only screen and (max-width: 1200px) {
    width: calc(50% - 103px);
  }
  @media only screen and (max-width: 1024px) {
    width: calc(50% - 63px);
  }
  @media only screen and (max-width: 900px) {
    width: calc(100% - 123px);
  }
  @media only screen and (max-width: 768px) {
    width: calc(100% - 43px);
  }
`;
*/
const ArticleVideoTable = styled.table`
  @media only screen and (max-width: 900px) {
    display: none;
  }
`;
const ArticleVideoTable900 = styled.table`
  @media only screen and (min-width: 901px) {
    display: none;
  }
`;
const ArticleVideoDescription = styled.div`
  bottom: 0;
  position: absolute;
  color: white;
  margin-bottom: 30px;
  margin-left: 30px;
  font-size: 40px;
  font-weight: 700;
  @media only screen and (max-width: 768px) {
    font-size: 32px;
  }
  @media only screen and (max-width: 480px) {
    font-size: 20px;
  }
`;
const ArticleVideoUpper = styled.div`
  margin-left: 50px;
  margin-right: 50px;
  @media only screen and (max-width: 768px) {
    margin-right: 20px;
  }
  @media only screen and (max-width: 900px) {
    margin-left: 0px;
    margin-right: 20px;
  }
  @media only screen and (max-width: 480px) {
    margin-right: 0px;
  }
`;
const ChillfulActivityListStyle2 = styled.div`
  margin-left: 100px;
  margin-right: 100px;
  @media only screen and (max-width: 1300px) {
    margin-left: 50px;
    margin-right: 50px;
  }
  @media only screen and (max-width: 900px) {
    margin-left: 20px;
    margin-right: 20px;
  }
`;
const BrowseArticlesTitle = styled.h1`
  font-size: 100px;
  color: #f8f4ec;
  margin-left: 30px;
  z-index: -1;
  position: relative;
  line-height: 90px;
  margin-bottom: 25px;
`;
const ActivityIntroDiv = styled.div`
  background-color: #f9f9f9;
  margin-top: -1px;
  padding: 70px 150px 0px 150px;
  overflow: hidden !important;
  position: relative;
  @media only screen and (max-width: 1200px) {
    padding: 70px 100px 0px 100px;
  }
  @media only screen and (max-width: 1024px) {
    padding: 70px 60px 0px 60px;
  }
  @media only screen and (max-width: 900px) {
    padding: 70px 60px 0px 60px;
  }
  @media only screen and (max-width: 768px) {
    padding: 70px 20px 0px 20px;
  }
  @media only screen and (max-width: 480px) {
    padding: 70px 20px 0px 20px;
  }
`;
const ActivityIntroImg = styled.div`
  background-size: cover;
  background-position: center;
  width: 12vw;
  height: 12vw;
  border-radius: 1000px;
  overflow: hidden !important;
  transition: all 1s;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  @media only screen and (max-width: 991px) {
    width: 20vw;
    height: 20vw;
  }
  @media only screen and (max-width: 575px) {
    width: 50vw;
    height: 50vw;
  }
  @media only screen and (max-width: 480px) {
    width: 40vw;
    height: 40vw;
  }
`;
const ActivityIntroImgContainer = styled.div`
  width: 12vw;
  height: 12vw;
  border-radius: 1000px;
  margin-bottom: 20px;
  margin-top: 80px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  @media only screen and (max-width: 991px) {
    width: 20vw;
    height: 20vw;
  }
  @media only screen and (max-width: 575px) {
    width: 50vw;
    height: 50vw;
  }
  @media only screen and (max-width: 480px) {
    width: 40vw;
    height: 40vw;
    margin-top: 50px;
  }
`;
const ActivityIntroHeader = styled.h1`
  font-size: 60px;
  color: black;
  margin-top: 50px;
  @media only screen and (max-width: 1024px) {
    font-size: 45px;
  }
  @media only screen and (max-width: 768px) {
    font-size: 40px;
  }
  @media only screen and (max-width: 480px) {
    font-size: 35px;
  }
`;
const ActivityIntroSubHeader = styled.h1`
  font-size: 20px;
  color: #444;
  margin-top: 10px;
  @media only screen and (max-width: 1024px) {
    font-size: 18px;
  }
  @media only screen and (max-width: 768px) {
    font-size: 16px;
  }
  @media only screen and (max-width: 480px) {
    font-size: 16px;
  }
`;

const MindfulIntroSubHeader = styled.h1`
  font-size: 20px;
  color: #444;
  margin-top: 10px;
  width: 50vw;
  @media only screen and (max-width: 1024px) {
    font-size: 18px;
    width: 60vw;
  }
  @media only screen and (max-width: 768px) {
    font-size: 16px;
    width: 80vw;
  }
  @media only screen and (max-width: 480px) {
    font-size: 16px;
    width: 90vw;
  }
`;

const ChillfulBannerIcon = styled.img`
  height: 30px;
  width: 30px;
  margin-bottom: -3px;
  margin-right: 10px;
  @media only screen and (max-width: 480px) {
    height: 24px;
    width: 24px;
    margin-right: 8px;
  }
`;
const AmbientMusicTitle = styled.span`
  color: #333;
  font-weight: 600;
  font-size: 30px;
  margin-left: 0px;
  @media only screen and (max-width: 480px) {
    font-size: 24px;
  }
`;
const AmbientMusicSubTitle = styled.h1`
  color: #333;
  font-weight: 600;
  font-size: 24px;
  margin-left: 0px;
  @media only screen and (max-width: 768px) {
    font-size: 18px;
  }
  @media only screen and (max-width: 480px) {
    font-size: 16px;
  }
`;
const AmbientMusicMarginDiv = styled.div`
  height: 60px;
  @media only screen and (max-width: 1024px) {
    height: 40px;
  }
  @media only screen and (max-width: 768px) {
    height: 30px;
  }
  @media only screen and (max-width: 480px) {
    height: 20px;
  }
`;
const AppDemoDiv = styled.div`
  @media only screen and (max-width: 1200px) {
    margin-left: 80px;
    margin-right: 80px;
  }
  @media only screen and (max-width: 1024px) {
    margin-left: 20px;
    margin-right: 20px;
  }
  @media only screen and (max-width: 768px) {
    margin-left: 20px;
    margin-right: 20px;
  }
  @media only screen and (max-width: 480px) {
    margin-left: 20px;
    margin-right: 20px;
  }
`;

const AppDemoImage = styled.img`
  width: 250px !important;
  margin-left: 35px;
  margin-right: 35px;
  border-radius: 27px;
  @media only screen and (max-width: 1200px) {
    width: 200px !important;
    margin-left: 30px;
    margin-right: 30px;
  }
  @media only screen and (max-width: 1024px) {
    width: 200px !important;
    margin-left: 20px;
    margin-right: 20px;
  }
  @media only screen and (max-width: 768px) {
    width: 172px !important;
    margin-left: 20px;
    margin-right: 20px;
  }
  @media only screen and (max-width: 480px) {
    width: 150px !important;
    margin-left: 10px;
    margin-right: 10px;
  }
`;

const AppDemoImage2 = styled.img`
  width: 250px !important;
  margin-left: 35px;
  margin-right: 35px;
  border-radius: 27px;
  @media only screen and (max-width: 1200px) {
    width: 200px !important;
    margin-left: 30px;
    margin-right: 30px;
  }
  @media only screen and (max-width: 1024px) {
    width: 200px !important;
    margin-left: 20px;
    margin-right: 20px;
  }
  @media only screen and (max-width: 768px) {
    display: none !important;
    width: 200px;
  }
  @media only screen and (max-width: 480px) {
    display: none !important;
    width: 200px;
  }
`;

function Articles() {
  useEffect(() => {
    $('#article_1').hover(
      function () {
        $('#article_1_hover_box').css('transform', 'translate(8px, -8px)');
      },
      function () {
        $('#article_1_hover_box').css('transform', 'translate(0px, 0px)');
      },
    );
    $('#article_2').hover(
      function () {
        $('#article_2_hover_box').css('transform', 'translate(8px, -8px)');
      },
      function () {
        $('#article_2_hover_box').css('transform', 'translate(0px, 0px)');
      },
    );
    $('#article_3').hover(
      function () {
        $('#article_3_hover_box').css('transform', 'translate(8px, -8px)');
      },
      function () {
        $('#article_3_hover_box').css('transform', 'translate(0px, 0px)');
      },
    );
    $('#article_4').hover(
      function () {
        $('#article_4_hover_box').css('transform', 'translate(8px, -8px)');
      },
      function () {
        $('#article_4_hover_box').css('transform', 'translate(0px, 0px)');
      },
    );

    $('#playVideoBtn').click(function () {
      $('#playVideoDiv').css('display', 'block');
      $('body').css('overflow', 'hidden');
    });
    $('#hideVideoBtn').click(function () {
      $('#playVideoDiv').css('display', 'none');
      $('body').css('overflow', 'scroll');
    });

    $('#playVideoBtn900').click(function () {
      $('#playVideoDiv900').css('display', 'block');
      $('body').css('overflow', 'hidden');
    });
    $('#hideVideoBtn900').click(function () {
      $('#playVideoDiv900').css('display', 'none');
      $('body').css('overflow', 'scroll');
    });

    $('#container1').hover(
      function () {
        $('#imageCover1').css('transform', 'scale(1.2)');
      },
      function () {
        $('#imageCover1').css('transform', 'scale(1)');
      },
    );
    $('#container2').hover(
      function () {
        $('#imageCover2').css('transform', 'scale(1.2)');
      },
      function () {
        $('#imageCover2').css('transform', 'scale(1)');
      },
    );
    $('#container3').hover(
      function () {
        $('#imageCover3').css('transform', 'scale(1.2)');
      },
      function () {
        $('#imageCover3').css('transform', 'scale(1)');
      },
    );
    $('#container4').hover(
      function () {
        $('#imageCover4').css('transform', 'scale(1.2)');
      },
      function () {
        $('#imageCover4').css('transform', 'scale(1)');
      },
    );
  }, []);

  function toDownloadApp() {
    window.location.href = '../app';
  }

  function toCorporateDetail() {
    $('html, body').animate(
      {
        scrollTop: $('#demoScroll').offset().top,
      },
      1000,
    );
  }
  /*
    function toInfoEmail() {
      window.location.href = 'mailto:info@vivablee.com';
    }
    */
  function toPartnershipsEmail() {
    window.location.href = 'mailto:partnerships@vivablee.com';
  }

  return (
    <div>
      <div style={{ width: '100%' }}>
        <Menu page={'corporates'} />

        <ArticleVideoDiv>
          <ArticleVideoTable>
            <colgroup>
              <col style={{ width: '40%' }} />
              <col style={{ width: '60%' }} />
            </colgroup>
            <tbody>
              <tr>
                <td>
                  <BannerVideo></BannerVideo>
                </td>
                <td>
                  <ArticleVideoUpper>
                    <h1 style={{ fontSize: '50px', color: '#444' }}>
                      一站式
                    <br />
                    企業服務方案
                  </h1>
                    <p style={{ marginTop: '20px', fontSize: '18px', color: '#777' }}>
                      精神健康是社會不容忽視而且日益嚴重的問題。根據《香港精神健康調查2010-2013》，年齡介乎16-75歲的華裔成人當中，一般精神病的患病率為13.3%。亦有研究指出，香港僱員因為身心健康相關問題平均損失每年77天生產力，為企業每月帶來高達374萬港幣的損失。
                  </p>
                    <p style={{ marginTop: '20px', fontSize: '18px', color: '#777' }}>
                      顯然，員工的身心健康是企業穩健發展的重要基石。Vivablee
                      為企業提供訂製的員工身心健康方案，由專人安排定期的專屬休閒活動體驗以及線上和線下的冥想體驗。在讓員工時刻保持身心健康並在日常工作大展所長的同時，施展企業社會責任！
                  </p>

                    <BannerBtn onClick={toCorporateDetail}>了解更多</BannerBtn>
                  </ArticleVideoUpper>
                </td>
              </tr>
            </tbody>
          </ArticleVideoTable>

          <ArticleVideoTable900>
            <colgroup>
              <col style={{ width: '100%' }} />
            </colgroup>
            <tbody>
              <tr>
                <td>
                  <ArticleVideoUpper>
                    <h1 style={{ fontSize: '60px', color: '#444' }}>
                      一站式
                    <br />
                    企業服務方案
                  </h1>
                    <p style={{ marginTop: '20px', fontSize: '18px', color: '#777' }}>
                      精神健康是社會不容忽視而且日益嚴重的問題。根據《香港精神健康調查2010-2013》，年齡介乎16-75歲的華裔成人當中，一般精神病的患病率為13.3%。亦有研究指出，香港僱員因為身心健康相關問題平均損失每年77天生產力，為企業每月帶來高達374萬港幣的損失。
                  </p>
                    <p style={{ marginTop: '20px', fontSize: '18px', color: '#777' }}>
                      顯然，員工的身心健康是企業穩健發展的重要基石。Vivablee
                      為企業提供訂製的員工身心健康方案，由專人安排定期的專屬休閒活動體驗以及線上和線下的冥想體驗。在讓員工時刻保持身心健康並在日常工作大展所長的同時，施展企業社會責任！
                  </p>

                    <BannerBtn
                      onClick={toDownloadApp}
                      style={{ marginTop: '25px', marginBottom: '50px' }}
                    >
                      了解更多
                  </BannerBtn>
                  </ArticleVideoUpper>
                </td>
              </tr>
              <tr>
                <td>
                  <BannerVideo style={{ height: '300px' }}></BannerVideo>
                </td>
              </tr>
            </tbody>
          </ArticleVideoTable900>
        </ArticleVideoDiv>

        <svg
          style={{ backgroundColor: 'white', marginBottom: '-5px', marginTop: '-400px' }}
          width="100%"
          //height="auto"
          viewBox="0 0 3840 260"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <title>Path</title>
          <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path
              d="M-9.09494702e-13,0.5 L-9.09494702e-13,259.5 C543.289571,86.8333333 1184.99624,0.5 1925.12,0.5 C2499.39149,0.5 3137.68482,86.8333333 3840,259.5 L3840,0.5 L-9.09494702e-13,0.5 Z"
              id="Path"
              fill="#f9f9f9"
              transform="translate(1920.000000, 130.000000) scale(1, -1) translate(-1920.000000, -130.000000) "
            ></path>
          </g>
        </svg>

        <div id="demoScroll"></div>

        <ActivityIntroDiv>
          <ActivityIntroHeader>專屬休閒活動體驗</ActivityIntroHeader>
          <ActivityIntroSubHeader>
            Vivablee
            提供一站式服務方案，從活動策劃到執行，都有專業的企業服務團隊為你度身訂造專屬的休閒活動體驗，讓員工在舒展身心的同時增強團隊歸屬感！
        </ActivityIntroSubHeader>
          <Container>
            <Row>
              <Col lg={3} sm={6}>
                <center>
                  <div style={{ marginLeft: 10, marginRight: 10 }}>
                    <ActivityIntroImgContainer id="container1">
                      <ActivityIntroImg
                        id="imageCover1"
                        style={{ backgroundImage: 'url(' + activity_intro_img_1 + ')' }}
                      ></ActivityIntroImg>
                    </ActivityIntroImgContainer>
                    <h4 style={{ fontSize: '22px', marginTop: 25, marginBottom: 10 }}>活動提議</h4>
                    <p>
                      Vivablee可以根據企業的需求，包括活動類別、參與人數及預算等資料，提議合適的活動供企業選擇。
                  </p>
                  </div>
                </center>
              </Col>
              <Col lg={3} sm={6}>
                <center>
                  <div style={{ marginLeft: 10, marginRight: 10 }}>
                    <ActivityIntroImgContainer id="container2">
                      <ActivityIntroImg
                        id="imageCover2"
                        style={{ backgroundImage: 'url(' + activity_intro_img_2 + ')' }}
                      ></ActivityIntroImg>
                    </ActivityIntroImgContainer>
                    <h4 style={{ fontSize: '22px', marginTop: 25, marginBottom: 10 }}>多方報價</h4>
                    <p>
                      根據企業的選擇，Vivablee會聯絡其合作夥伴為專屬活動作出多方報價，讓企業可以快捷方便地比較不同活動提供方的方案。
                  </p>
                  </div>
                </center>
              </Col>
              <Col lg={3} sm={6}>
                <center>
                  <div style={{ marginLeft: 10, marginRight: 10 }}>
                    <ActivityIntroImgContainer id="container3">
                      <ActivityIntroImg
                        id="imageCover3"
                        style={{ backgroundImage: 'url(' + activity_intro_img_3 + ')' }}
                      ></ActivityIntroImg>
                    </ActivityIntroImgContainer>
                    <h4 style={{ fontSize: '22px', marginTop: 25, marginBottom: 10 }}>活動細節安排</h4>
                    <p>Vivablee會作為企業與活動提供方的橋樑，度身訂造符合企業需求的休閒活動體驗。</p>
                  </div>
                </center>
              </Col>
              <Col lg={3} sm={6}>
                <center>
                  <div style={{ marginLeft: 10, marginRight: 10 }}>
                    <ActivityIntroImgContainer id="container4">
                      <ActivityIntroImg
                        id="imageCover4"
                        style={{ backgroundImage: 'url(' + activity_intro_img_4 + ')' }}
                      ></ActivityIntroImg>
                    </ActivityIntroImgContainer>
                    <h4 style={{ fontSize: '22px', marginTop: 25, marginBottom: 10 }}>活動支援</h4>
                    <p>在活動當天，Vivablee會提供現場支援，確保員工能順利參與休閒活動體驗。</p>
                  </div>
                </center>
              </Col>
            </Row>
          </Container>
          <center>
            <BannerBtn style={{ marginTop: 80 }} onClick={toPartnershipsEmail}>
              聯絡訂製你的專屬企業方案
          </BannerBtn>
          </center>
        </ActivityIntroDiv>

        <svg
          style={{ backgroundColor: 'white', marginBottom: '-7px' }}
          width="100%"
          //height="auto"
          viewBox="0 0 3840 259"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <title>Path</title>
          <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path
              d="M0,0 C539.876237,172.666667 1179.87624,259 1920,259 C2494.27149,259 3134.27149,172.666667 3840,0 L0,0 Z"
              id="Path"
              fill="#f9f9f9"
            ></path>
          </g>
        </svg>

        <ActivityIntroDiv style={{ backgroundColor: 'white' }}>
          <ActivityIntroHeader style={{ textAlign: 'center' }}>冥想體驗</ActivityIntroHeader>
          <center>
            <MindfulIntroSubHeader style={{ textAlign: 'center' }}>
              恆常的冥想練習可以讓員工在忙碌的工作中放緩思緒，舒展身心。Vivablee
              提供線上和線下冥想練習，讓員工可以時刻體驗靜觀。
          </MindfulIntroSubHeader>
          </center>
        </ActivityIntroDiv>

        <ActivityIntroDiv style={{ backgroundColor: 'white' }}>
          <ChillfulBannerIcon
            src={require('../images/general/mindful_icon_original.png')}
          ></ChillfulBannerIcon>
          <AmbientMusicTitle style={{ marginTop: 0 }}>線上體驗</AmbientMusicTitle>
          <div style={{ height: 5 }}></div>
          <AmbientMusicSubTitle style={{ marginTop: 0 }}>
            企業員工可以免費下載Vivablee應用程式，
          <br />
          隨時使用Vivablee的廣東話冥想練習庫以及靜觀旅程體驗。
        </AmbientMusicSubTitle>
          <AmbientMusicMarginDiv></AmbientMusicMarginDiv>
        </ActivityIntroDiv>

        <center>
          <AppDemoDiv>
            <div style={{ marginTop: '0px' }}>
              <AppDemoImage
                src={require('../images/mindful/dailyRoutine_1_zh_HK.png')}
              ></AppDemoImage>
              <AppDemoImage
                src={require('../images/mindful/dailyRoutine_2_zh_HK.png')}
              ></AppDemoImage>
              <AppDemoImage2
                src={require('../images/mindful/dailyRoutine_3_zh_HK.png')}
              ></AppDemoImage2>
            </div>
          </AppDemoDiv>
        </center>

        <ActivityIntroDiv style={{ backgroundColor: 'white', marginTop: 30 }}>
          <ChillfulBannerIcon
            src={require('../images/general/mindful_icon_original.png')}
          ></ChillfulBannerIcon>
          <AmbientMusicTitle style={{ marginTop: 0 }}>線下體驗</AmbientMusicTitle>
          <div style={{ height: 5 }}></div>
          <AmbientMusicSubTitle style={{ marginTop: 0 }}>
            Vivablee可以安排導師親臨企業辦公室，
          <br />
          讓員工一起體驗靜觀。
        </AmbientMusicSubTitle>
          <AmbientMusicMarginDiv></AmbientMusicMarginDiv>
          <img
            style={{ borderRadius: 20, width: '100%', marginBottom: 50 }}
            src={require('../images/corporates/mindful.jpg')}
            alt="mindful"
          ></img>
        </ActivityIntroDiv>

        <center>
          <BannerBtn style={{ marginTop: 0 }} onClick={toPartnershipsEmail}>
            聯絡訂製你的專屬企業方案
        </BannerBtn>
        </center>

        <div style={{ height: 100 }}></div>

        <Footer />
      </div>
    </div>
  );
}

export default Articles;
