import React from 'react';
//import React, {useState, useEffect} from 'react';
//import {BrowserRouter as Router, Switch, Route, Link, useParams} from 'react-router-dom';
//import {storage, db} from '../firebase';

function PrivacyPolicy() {
  return (
    <div style={{ marginTop: 50, marginBottom: 50 }}>
      <h1 style={{ textAlign: 'center', marginBottom: 20 }}>Privacy Policy</h1>
      <p style={{ marginLeft: 100, marginRight: 100, marginTop: 10 }}>
        Vivablee Limited (“Vivablee”, “we”, “us”, “our”) is a limited company incorporated in Hong
        Kong. This privacy policy governs the manner of which personal information is collected and
        used by us. You must agree to the collection and use of information in relation to this
        policy in order to use our services.
      </p>
      <h4 style={{ marginLeft: 100, marginRight: 100, marginTop: 10 }}>
        Information Collection and Use
      </h4>
      <p style={{ marginLeft: 100, marginRight: 100, marginTop: 10 }}>
        We may require you to provide us with certain personal information while using our mobile
        application to optimize your user experience, including, but not limited to, email address,
        first and last name, telephone number, address, real time location and social media profile
        information.
      </p>
      <p style={{ marginLeft: 100, marginRight: 100, marginTop: 10 }}>
        Our mobile application uses services from third parties who may collect your personal
        information. You may access the privacy policies of third party service providers through
        the links below:
      </p>
      <ul style={{ marginLeft: 140, marginRight: 100, marginTop: 10 }}>
        <li>Google Play Services</li>
        <li>Google Analytics for Firebase</li>
        <li>Firebase Crashlytics</li>
        <li>Facebook</li>
        <li>One Signal</li>
        <li>AppLovin</li>
      </ul>
      <h4 style={{ marginLeft: 100, marginRight: 100, marginTop: 10 }}>Log Data</h4>
      <p style={{ marginLeft: 100, marginRight: 100, marginTop: 10 }}>
        We collect Log Data, which are data and information on your mobile device pertaining to
        errors that you encountered whilst using our mobile application. Log Data may include
        information such as your device Internet Protocol address, device name, operating system
        version, the configuration of the app when utilizing our mobile application, the time and
        date of your use of the Service, and other statistics.
      </p>
      <h4 style={{ marginLeft: 100, marginRight: 100, marginTop: 10 }}>Email Communication</h4>
      <p style={{ marginLeft: 100, marginRight: 100, marginTop: 10 }}>
        We may regularly send emails to you about latest promotions, events, or any other
        information that we wish to communicate if you have previously opted-in to receive emails
        from us.
      </p>
      <h4 style={{ marginLeft: 100, marginRight: 100, marginTop: 10 }}>Cookies</h4>
      <p style={{ marginLeft: 100, marginRight: 100, marginTop: 10 }}>
        Cookies are files with a small amount of data that are commonly used as anonymous unique
        identifiers. These are sent to your browser from the websites that you visit and are stored
        on your device's internal memory. We do not use these cookies explicitly.
      </p>
      <p style={{ marginLeft: 100, marginRight: 100, marginTop: 10 }}>
        However, we may use third party code and libraries that use cookies to collect information.
      </p>
      <p style={{ marginLeft: 100, marginRight: 100, marginTop: 10 }}>
        You have the option to either accept or refuse cookies and know when a cookie is being sent
        to your device. If you choose to refuse cookies, you may not be able to use some portions of
        our mobile application.
      </p>
      <h4 style={{ marginLeft: 100, marginRight: 100, marginTop: 10 }}>Service Providers</h4>
      <p style={{ marginLeft: 100, marginRight: 100, marginTop: 10 }}>
        We may employ third parties due to the following reasons:
      </p>
      <ul style={{ marginLeft: 140, marginRight: 100, marginTop: 10 }}>
        <li>To facilitate the services offered in our mobile application;</li>
        <li>To provide any services on our behalf; or</li>
        <li>
          To assist us in analyzing information pertaining to the services made available through
          the mobile application.
        </li>
      </ul>
      <p style={{ marginLeft: 100, marginRight: 100, marginTop: 10 }}>
        These third parties may have access to your personal information in order to perform the
        tasks assigned to them on our behalf. However, they are obligated not to disclose or use the
        information for any other purpose.
      </p>
      <h4 style={{ marginLeft: 100, marginRight: 100, marginTop: 10 }}>Security</h4>
      <p style={{ marginLeft: 100, marginRight: 100, marginTop: 10 }}>
        We value your trust in providing us with your personal information, and thus we strive to
        use commercially acceptable means to protect it. Yet, there is no method of transmission
        over the internet, or method of electronic storage, that is 100% secure and reliable, and we
        cannot guarantee its absolute security.
      </p>
      <h4 style={{ marginLeft: 100, marginRight: 100, marginTop: 10 }}>Links to Other Sites</h4>
      <p style={{ marginLeft: 100, marginRight: 100, marginTop: 10 }}>
        Our mobile application may contain links to other sites and you may be directed to those
        sites by clicking those links. These external sites are not operated by us. You are strongly
        advised to review the privacy policies of these websites. We have no control over and assume
        no responsibility for the content, privacy policies, or practices of any third party sites
        or services.
      </p>
      <h4 style={{ marginLeft: 100, marginRight: 100, marginTop: 10 }}>
        Changes to This Privacy Policy
      </h4>
      <p style={{ marginLeft: 100, marginRight: 100, marginTop: 10 }}>
        We may update our Privacy Policy from time to time. You are advised to review this page
        periodically for any changes. We will notify you of any changes by posting the new Privacy
        Policy on this page.
      </p>
      <p style={{ marginLeft: 100, marginRight: 100, marginTop: 10 }}>
        This policy is effective as of 2021-01-08
      </p>
      <h4 style={{ marginLeft: 100, marginRight: 100, marginTop: 10 }}>Contact Us</h4>
      <p style={{ marginLeft: 100, marginRight: 100, marginTop: 10 }}>
        If you have any questions about our privacy policy, please contact us at info@vivablee.com.
      </p>
    </div>
  );
}

export default PrivacyPolicy;
