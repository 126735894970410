import React from 'react';
import "./App.css";
import ScrollToTop from "./functions/ScrollToTop";
import Home from "./pages/Home";
import Activity from "./pages/Activity";
import Articles from "./pages/Articles";
import Article from "./pages/Article";
import Corporates from "./pages/Corporates";
import Meditation from "./pages/Meditation";
import AtoZ from "./pages/AtoZ";
import AtozPage from "./pages/AtozPage";
import ActivityDetails from "./pages/ActivityDetails";
import Dashboard from "./pages/Dashboard";
import ActivityUpdate from "./pages/ActivityUpdate";
import Downloads from "./pages/Downloads";
import Marketing from "./pages/Marketing";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import WriteDataTemperature from "./pages/WriteDataTemperature";
import DownloadManatee from "./pages/DownloadManatee";
//import SitemapGenerator from "./pages/SitemapGenerator";
//import AddAtoZContent from "./pages/admin/AddAtoZContent";
import { BrowserRouter as Router, Route } from 'react-router-dom';
//import { Helmet, HelmetProvider } from "react-helmet-async";
import { Helmet } from "react-helmet";
//import { storage, db } from './firebase';
//import { useParams } from 'react-router-dom';
//import HomeMeta from './Meta/HomeMeta';

function App() {

  const HOME = () => (
    <div>
      <Helmet>
        <title>vivablee</title>
        <meta
          name="description"
          content="Vivablee 是一個心理健康服務的綜合平台。"
        />
        <meta name="keywords" content="休閒活動,冥想空間,免費試用,身心健康" />
      </Helmet>
      <Home />
    </div>
  );

  const ACTIVITIES = () => (
    <div>
      <Helmet>
        <title>特色活動</title>
        <meta name="description" content="Vivablee 提供一系列線上休閒活動體驗，讓你在家中亦能放鬆自己。" />
        <meta name="keywords" content="休閒活動,工作坊,Chill At Home,活動精選" />
      </Helmet>
      <Activity />
    </div>
  );

  const MEDITATION = () => (
    <div>
      <Helmet>
        <title>冥想空間</title>
        <meta name="description" content="透過靜觀旅程，體驗不同的冥想練習，並可以在建立恆常練習的習慣的同時，獲得不同的獎賞。" />
        <meta name="keywords" content="冥想旅程,冥想練習,靜觀體驗,冥想空間" />
      </Helmet>
      <Meditation />
    </div>
  );

  const CORPORATES = () => (
    <div>
      <Helmet>
        <title>企業服務</title>
        <meta name="description" content="Vivablee 為企業提供訂製的員工身心健康方案，由專人安排定期的專屬休閒活動體驗以及線上和線下的冥想體驗。在讓員工時刻保持身心健康並在日常工作大展所長的同時，施展企業社會責任！" />
        <meta name="keywords" content="企業服務,休閒活動,冥想體驗,精神健康" />
      </Helmet>
      <Corporates />
    </div>
  );

  const ARTICLES = () => (
    <div>
      <Helmet>
        <title>認識靜觀</title>
        <meta name="description" content="Vivablee 精選文章" />
        <meta name="keywords" content="認識靜觀,Vivablee,精選文章" />
      </Helmet>
      <Articles />
    </div>
  );

  const ATOZ = () => (
    <div>
      <Helmet>
        <title>Viva百科</title>
        <meta name="description" content="Vivablee AtoZ 百科" />
        <meta name="keywords" content="A to Z,百科,a to z,Vivablee" />
      </Helmet>
      <AtoZ />
    </div>
  );

  /*
    const helmetContext = {};

    const HOME = () => (
      <div>
        <title>vivablee</title>
        <HelmetProvider context={helmetContext}>
          <Helmet>
            <title>vivablee</title>
            <meta
              name="description"
              content="Vivablee 是一個心理健康服務的綜合平台。"
            />
            <meta name="keywords" content="休閒活動,冥想空間,免費試用,身心健康" />
          </Helmet>
        </HelmetProvider>
        <Home />
      </div>
    );

    const ACTIVITIES = () => (
      <div>
        <title>特色活動</title>
        <HelmetProvider context={helmetContext}>
          <Helmet>
            <title>特色活動</title>
            <meta name="description" content="Vivablee 提供一系列線上休閒活動體驗，讓你在家中亦能放鬆自己。" />
            <meta name="keywords" content="休閒活動,工作坊,Chill At Home,活動精選" />
          </Helmet>
        </HelmetProvider>
        <Activity />
      </div>
    );

    const MEDITATION = () => (
      <div>
        <title>冥想空間</title>
        <HelmetProvider context={helmetContext}>
          <Helmet>
            <title>冥想空間</title>
            <meta name="description" content="透過靜觀旅程，體驗不同的冥想練習，並可以在建立恆常練習的習慣的同時，獲得不同的獎賞。" />
            <meta name="keywords" content="冥想旅程,冥想練習,靜觀體驗,冥想空間" />
          </Helmet>
        </HelmetProvider>
        <Meditation />
      </div>
    );

    const CORPORATES = () => (
      <div>
        <title>企業服務</title>
        <HelmetProvider context={helmetContext}>
          <Helmet>
            <title>企業服務</title>
            <meta name="description" content="Vivablee 為企業提供訂製的員工身心健康方案，由專人安排定期的專屬休閒活動體驗以及線上和線下的冥想體驗。在讓員工時刻保持身心健康並在日常工作大展所長的同時，施展企業社會責任！" />
            <meta name="keywords" content="企業服務,休閒活動,冥想體驗,精神健康" />
          </Helmet>
        </HelmetProvider>
        <Corporates />
      </div>
    );

    const ARTICLES = () => (
      <div>
        <title>認識靜觀</title>
        <HelmetProvider context={helmetContext}>
          <Helmet>
            <title>認識靜觀</title>
            <meta name="description" content="Vivablee 精選文章" />
            <meta name="keywords" content="認識靜觀" />
          </Helmet>
        </HelmetProvider>
        <Articles />
      </div>
    );

    //const html = renderToString(HOME);
    //console.log(html);
    //const { helmet } = helmetContext;
    //console.log(helmet.title.toString);

    /*
      const [metaTitle, setMetaTitle] = useState("vivablee");
      const [metaContent, setMetaContent] = useState("Vivablee 是一個心理健康服務的綜合平台。");
      const [metaKeywords, setMetaKeywords] = useState("休閒活動,冥想空間,免費試用,身心健康");

      const HOME = () => (
        <div>
          <HelmetProvider>
            <Helmet>
              <title>{metaTitle}</title>
              <meta
                name="description"
                content={metaContent}
              />
              <meta name="keywords" content={metaKeywords} />
            </Helmet>
          </HelmetProvider>
          <Home />
        </div>
      );

      const ACTIVITIES = () => (
        <div>
          <Activity />
        </div>
      );

      const MEDITATION = () => (
        <div>
          <Meditation />
        </div>
      );

      const CORPORATES = () => (
        <div>
          <Corporates />
        </div>
      );

      const ARTICLES = () => (
        <div>
          <HelmetProvider>
            <title>{metaTitle}</title>
            <Helmet>
              <meta name="description" content="Vivablee 精選文章" />
              <meta name="keywords" content="認識靜觀" />
            </Helmet>
          </HelmetProvider>
          <Articles />
        </div>
      );

      function MetaChange({ pageName }) {
        setMetaTitle(pageName);
        setMetaContent("okay");
        return (
          <div>
            <HelmetProvider>
              <Helmet>
                <title>{metaTitle}</title>
                <meta
                  name="description"
                  content={metaContent}
                />
                <meta name="keywords" content={metaKeywords} />
              </Helmet>
            </HelmetProvider>
            <Home />
          </div>
        );
      }
    */

  const ARTICLE = () => (
    <Article />
  );

  const ATOZPAGE = () => (
    <AtozPage />
  );

  return (
    <div>
      <Router>
        <ScrollToTop />
        <div style={{ width: "100%" }}>
          <Route exact path="/">
            <HOME />
            {/* <MetaChange pageName="vivablee" /> */}
          </Route>
          <Route exact path="/Activities">
            <ACTIVITIES />
            {/* <MetaChange pageName="activities" /> */}
          </Route>
          <Route exact path="/Activities/Details" component={ActivityDetails} />
          <Route exact path="/Articles">
            <ARTICLES />
          </Route>
          <Route exact path="/Articles/:p">
            <ARTICLE />
          </Route>
          <Route exact path="/Corporates">
            <CORPORATES />
          </Route>
          <Route exact path="/Meditation">
            <MEDITATION />
          </Route>
          {/* new add AtoZ */}
          <Route exact path="/AtoZ">
            <ATOZ />
          </Route>
          <Route exact path="/AtoZ/:page">
            <ATOZPAGE />
          </Route>
          {/* <Route exact path="/dynamic_sitemap.xml" component={SitemapGenerator} /> */}
          <Route exact path="/Business/Dashboard" component={Dashboard} />
          <Route exact path="/Business/ActivityUpdate" component={ActivityUpdate} />
          <Route exact path="/app" component={Downloads} />
          <Route exact path="/manatee" component={DownloadManatee} />
          <Route exact path="/Marketing" component={Marketing} />
          <Route exact path="/PrivacyPolicy" component={PrivacyPolicy} />
          <Route exact path="/WriteData/Temperature/:data?" component={WriteDataTemperature} />
          {/* <Route exact path="/sitemap.xml" component={Sitemap} /> */}
        </div>
      </Router>
    </div>
  );
}
export default App;
