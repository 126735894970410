import React, { useState, useEffect, useRef } from 'react';
//import { Link } from 'react-router-dom';
import ActivityCard from '../components/ActivityCard';
//import ActivitySearchBox from '../components/ActivitySearchBox';
import Menu from '../components/Menu';
import Footer from '../components/Footer';
//import Demo from '../components/Demo';
//import BrowseArticles from '../components/BrowseArticles';
import styled from 'styled-components'; // package to define css class
import $ from 'jquery'; // package to run jQuery
//import img from '../images/chillful/event_1.jpg';
import img_event_1 from '../images/chillful/event_1.jpg';
//import img_article_1 from '../images/articles/article1.jpg';
//import img_article_2 from '../images/articles/article2.jpg';
//import img_article_3 from '../images/articles/article3.jpg';
//import img_article_4 from '../images/articles/article4.jpg';
//import pattern_background from '../images/general/pattern_background.jpg';
//import demo1 from '../images/mp3/demo1.mp3';
import { db } from '../firebase.js';
//import Slider from 'infinite-react-carousel';
//import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import PopupModal from '../components/PopupModal';
import { useTranslation } from 'react-i18next';
//import { Helmet, HelmetProvider } from "react-helmet-async";
import {
  isDesktop,
  isIOS
} from "react-device-detect";

const BannerBtn = styled.button`
  padding: 20px 35px;
  color: white;
  border-radius: 50px;
  border-style: none;
  background-color: #ff9700;
  font-weight: 700;
  font-size: 16px;
  box-shadow: 0px 3px 12px 2px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  margin-top: 30px;
`;
const BannerBtn2 = styled.button`
  padding: 5px 11px;
  color: white;
  border-radius: 50px;
  border-style: none;
  background-color: #ff585d;
  font-weight: 700;
  font-size: 16px;
  box-shadow: 0px 3px 12px 2px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  margin-top: 30px;
`;

const ChillfulActivityListStyle2 = styled.div`
  @media only screen and (max-width: 1300px) {
  }
  @media only screen and (max-width: 900px) {
  }
`;
const ChillfulActivityListStyle2Arrow = styled.img`
  height: 20px;
  vertical-align: middle;
  margin-bottom: 6px;
  margin-top: 0px;
  margin-left: 5px;
  alt: "arrow";
`;
const ChillfulActivityListStyle2Title = styled.span`
  font-size: 18px;
`;
const ChillfulColWidth50 = styled.col`
  width: 50%;
  @media only screen and (max-width: 800px) {
    width: 50%;
  }
  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`;
const ChillfulColWidth25Long2 = styled.col`
  width: 25%;
  @media only screen and (max-width: 800px) {
    width: 50%;
  }
  @media only screen and (max-width: 600px) {
    width: 0%;
  }
`;
const ChillfulColWidth25Long = styled.col`
  width: 25%;
  @media only screen and (max-width: 1200px) {
    display: none;
  }
`;
const ChillfulActivityListIcon = styled.img`
  height: 12px;
  margin-right: 2px;
  display: inline;
`;
const ChillfulActivityListStyle2Container = styled.div`
  position: relative;
  width: 100%;
  padding-top: calc(80% - 36px);
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  overflow: hidden;
`;
const ChillfulActivityListStyle2ContainerTd = styled.td`
  display: none;
  @media only screen and (max-width: 800px) {
    display: block;
  }
  @media only screen and (max-width: 600px) {
    display: none;
  }
`;
const ChillfulActivityListStyle2ContainerDiv1 = styled.div`
  cursor: pointer;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  box-shadow: 0px 3px 12px 2px rgba(0, 0, 0, 0.05);
  @media only screen and (max-width: 800px) {
    margin-right: 5px;
  }
`;
const ChillfulActivityListStyle2ContainerDiv2 = styled.div`
  cursor: pointer;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  box-shadow: 0px 3px 12px 2px rgba(0, 0, 0, 0.05);
  @media only screen and (max-width: 800px) {
    margin-left: 5px;
  }
`;
const ChillfulActivityListStyle2TdLong = styled.td`
  @media only screen and (max-width: 1200px) {
    display: none;
  }
`;
const ChillfulActivityListStyle2TdLong2 = styled.td`
  @media only screen and (max-width: 800px) {
    display: none;
  }
`;
const ChillfulActivityListStyle2ImageCover = styled.div`
  background-size: cover;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-image: url(${img_event_1});
  transition: all 1s;
`;
const ChillfulActivityListStyle2ClassBottom = styled.div`
  height: 118px;
  background-color: white;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  padding: 15px 20px 0px 20px;
  color: black;
  @media only screen and (max-width: 800px) {
    height: 100px;
  }
`;
/*
const ChillfulActivityListStyle2ClassBottomText1 = styled.div`
  font-size: 14px;
  color: #ff585d;
  display: inline;
  margin-left: 3px;
  font-weight: 700;
`;
*/
const MindfulListStyle2ClassBottomText1 = styled.div`
  font-size: 14px;
  color: #ff9700;
  display: inline;
  margin-left: 3px;
  font-weight: 700;
  text-transform: capitalize;
`;
const ChillfulActivityListStyle2ClassBottomText2 = styled.div`
  font-size: 28px;
  line-height: 32px;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  @media only screen and (max-width: 800px) {
    font-size: 20px;
    line-height: 24px;
  }
`;
const ChillfulActivityListStyle2ClassBottomSmallText2 = styled.div`
  font-size: 20px;
  line-height: 22px;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;
const ChillfulActivityListStyle2ClassBottomText3 = styled.div`
  font-size: 18px;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  @media only screen and (max-width: 800px) {
    font-size: 14px;
  }
`;
const ChillfulActivityListStyle2ClassBottomText4 = styled.div`
  font-size: 16px;
  margin-top: 4px;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  @media only screen and (max-width: 800px) {
    font-size: 14px;
  }
`;
const ChillfulActivityListStyle2Container2 = styled.div`
  position: relative;
  width: 100%;
  padding-top: calc(80% - 72px);
  overflow: hidden;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  @media only screen and (max-width: 1200px) {
    padding-top: calc(80% - 77px);
  }
`;
const ChillfulActivityListStyle2Container3 = styled.div`
  position: relative;
  width: 100%;
  padding-top: calc(40% - 72px);
  overflow: hidden;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
`;
const ChillfulActivityListStyle2ClassBottom2 = styled.div`
  transition: height 0.5s;
  height: 100px;
  background-color: white;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  padding: 10px 20px 0px 20px;
  color: black;
  @media only screen and (max-width: 1200px) {
    height: 118px;
    padding: 15px 20px 0px 20px;
  }
`;
const ChillfulActivityListStyle2Small1 = styled.div`
  margin-left: 24px;
  cursor: pointer;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  box-shadow: 0px 3px 12px 2px rgba(0, 0, 0, 0.05);
`;
const ChillfulActivityListStyle2Small2 = styled.div`
  margin-left: 24px;
  margin-top: 24px;
  cursor: pointer;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  box-shadow: 0px 3px 12px 2px rgba(0, 0, 0, 0.05);
  @media only screen and (max-width: 1200px) {
    display: none;
  }
`;
const ChillfulActivityListStyle2Small3 = styled.div`
  margin-left: 24px;
  margin-top: 24px;
  display: none;
  cursor: pointer;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  box-shadow: 0px 3px 12px 2px rgba(0, 0, 0, 0.05);
  @media only screen and (max-width: 1200px) {
    display: inline-block;
  }
  @media only screen and (max-width: 800px) {
    display: none;
  }
`;
/*
const PatternBackground = styled.div`
  background-image: url(${pattern_background});
  background-size: cover;
  width: 100%;
  height: 500px;
  margin-top: -15px;
  border-radius: 10px;
  @media only screen and (max-width: 768px) {
    height: 400px;
  }
`;
const BannerBtn3 = styled.button`
  padding: 0px 7px 1px 7px;
  color: white;
  border-radius: 50px;
  border-style: none;
  background-color: #ff9700;
  font-weight: 700;
  font-size: 16px;
  box-shadow: 0px 3px 12px 2px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  margin-left: 20px;
`;
*/
const TopHeaderTop = styled.h1`
  font-size: 60px;
  color: #444;
  @media only screen and (max-width: 1200px) {
    font-size: 50px;
  }
  @media only screen and (max-width: 980px) {
    font-size: 45px;
  }
  @media only screen and (max-width: 768px) {
    font-size: 40px;
  }
  @media only screen and (max-width: 480px) {
    font-size: 40px;
  }
`;

const TopHeader = styled.h1`
  font-size: 90px;
  line-height: 90px;
  color: #444;
  white-space: pre-line;
  @media only screen and (max-width: 1200px) {
    font-size: 80px;
    line-height: 80px;
  }
  @media only screen and (max-width: 980px) {
    font-size: 65px;
    line-height: 65px;
  }
  @media only screen and (max-width: 768px) {
    font-size: 50px;
    line-height: 50px;
  }
  @media only screen and (max-width: 480px) {
    font-size: 50px;
    line-height: 50px;
  }
`;

const TopPara = styled.p`
  margin-top: 20px;
  font-size: 24px;
  width: 70%;
  @media only screen and (max-width: 1200px) {
    font-size: 20px;
  }
  @media only screen and (max-width: 768px) {
    font-size: 16px;
  }
  @media only screen and (max-width: 480px) {
    font-size: 16px;
    width: 90%;
  }
`;

const TopDiv = styled.div`
  background-color: #f4f2ec;
  margin-bottom: -1px;
  padding: 150px 150px 80px 150px;
  text-align: left;
  @media only screen and (max-width: 1200px) {
    padding: 150px 100px 80px 100px;
  }
  @media only screen and (max-width: 980px) {
    padding: 150px 80px 80px 50px;
  }
  @media only screen and (max-width: 768px) {
    padding: 150px 50px 80px 50px;
  }
  @media only screen and (max-width: 480px) {
    padding: 150px 20px 80px 20px;
  }
`;

const TopDivContain = styled.div`
  width: 70%;
  @media only screen and (max-width: 1200px) {
  }
  @media only screen and (max-width: 768px) {
    width: 60%;
  }
  @media only screen and (max-width: 650px) {
    width: 100%;
  }
  @media only screen and (max-width: 480px) {
    width: 100%;
  }
`;

const TopImageDiv = styled.div`
  height: 560px;
  width: 280px;
  border-radius: 40px;
  position: absolute;
  right: 0;
  margin-top: -540px;
  margin-right: 250px;
  transform: rotate(20deg);
  boxshadow: 5px 5px 10px 2px rgba(0, 0, 0, 0.1);
  @media only screen and (max-width: 1200px) {
    height: 500px;
    width: 250px;
    margin-right: 150px;
    margin-top: -480px;
  }
  @media only screen and (max-width: 980px) {
    height: 460px;
    width: 230px;
    margin-right: 100px;
    margin-top: -460px;
  }
  @media only screen and (max-width: 768px) {
    height: 400px;
    width: 220px;
    margin-right: 80px;
    margin-top: -400px;
  }
  @media only screen and (max-width: 650px) {
    display: none;
  }
`;

const TopImage = styled.img`
  height: 560px;
  @media only screen and (max-width: 1200px) {
    height: 500px;
  }
  @media only screen and (max-width: 768px) {
    height: 440px;
  }
`;

const ArticleTitle = styled.h1`
  font-size: 60px;
  color: #444;
  @media only screen and (max-width: 1200px) {
    font-size: 50px;
  }
  @media only screen and (max-width: 768px) {
    font-size: 40px;
  }
  @media only screen and (max-width: 480px) {
    font-size: 30px;
  }
`;

const ArticleTitleTop = styled.h1`
  font-size: 40px;
  color: #444;
  @media only screen and (max-width: 1200px) {
    font-size: 30px;
  }
  @media only screen and (max-width: 768px) {
    font-size: 20px;
  }
  @media only screen and (max-width: 480px) {
    font-size: 16px;
  }
`;

const ArticleTitleDiv = styled.div`
  position: absolute;
  right: 100px;
  margin-top: -40px;
  @media only screen and (max-width: 1200px) {
    margin-top: -15px;
    right: 50px;
  }
  @media only screen and (max-width: 768px) {
    margin-top: 50px;
    left: 20px;
  }
  @media only screen and (max-width: 480px) {
    margin-top: 60px;
    left: 20px;
  }
`;

const Margin20To40 = styled.div`
  height: 20px;
  @media only screen and (max-width: 768px) {
    height: 100px;
  }
`;

const DemoTitle = styled.h1`
  font-size: 60px;
  font-weight: 700;
  color: #444;
  text-align: center;
  margin-bottom: 25px;
  @media only screen and (max-width: 1200px) {
    font-size: 50px;
  }
  @media only screen and (max-width: 768px) {
    font-size: 40px;
  }
  @media only screen and (max-width: 480px) {
    font-size: 30px;
  }
`;

const DemoDiv = styled.div`
  margin-left: 150px;
  margin-right: 150px;
  margin-top: 220px;
  @media only screen and (max-width: 1200px) {
    margin-left: 100px;
    margin-right: 100px;
  }
  @media only screen and (max-width: 1024px) {
    margin-left: 50px;
    margin-right: 50px;
  }
  @media only screen and (max-width: 768px) {
    margin-left: 20px;
    margin-right: 20px;
  }
  @media only screen and (max-width: 480px) {
    margin-left: 20px;
    margin-right: 20px;
  }
`;

const DemoBtn = styled.div`
  border-radius: 10px;
  background-color: #ff9700;
  color: white;
  padding: 5px;
  width: 100px;
  text-align: center;
  display: inline-block;
  margin: 5px 5px;
  cursor: pointer;
  @media only screen and (max-width: 1200px) {
    padding: 5px;
    font-size: 14px;
  }
  @media only screen and (max-width: 1024px) {
    padding: 5px;
    font-size: 14px;
    width: 80px;
  }
`;

const DemoLineBreak = styled.br`
  display: none;
  @media only screen and (max-width: 768px) {
    display: block;
  }
`;

const DemoImageDiv1 = styled.div`
  height: 400px;
  border-radius: 40px;
  position: absolute;
  margin-top: -410px;
  margin-left: -50px;
  transform: rotate(20deg);
  boxshadow: 5px 5px 10px 2px rgba(0, 0, 0, 0.1);
  @media only screen and (max-width: 1200px) {
    margin-left: 0px;
    height: 300px;
  }
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

const DemoImageDiv2 = styled.div`
  height: 400px;
  border-radius: 40px;
  position: absolute;
  right: 0;
  margin-top: -450px;
  margin-right: 100px;
  transform: rotate(-10deg);
  box-shadow: 5px 5px 10px 2px rgba(0, 0, 0, 0.1);
  @media only screen and (max-width: 1200px) {
    margin-right: 0px;
    height: 300px;
  }
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

const DemoImage1 = styled.img`
  height: 400px;
  @media only screen and (max-width: 768px) {
    height: 300px;
  }
`;

const DemoImage2 = styled.img`
  height: 400px;
  @media only screen and (max-width: 768px) {
    height: 300px;
  }
`;

const DemoIntroDiv = styled.div`
  width: 35%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  @media only screen and (max-width: 1200px) {
    width: 50%;
  }
  @media only screen and (max-width: 768px) {
    width: 80%;
  }
`;

const DemoAudio = styled.audio`
  display: table;
  margin: 0px auto;
  margin-top: 30px;
  width: 500px;
  @media only screen and (max-width: 768px) {
    width: 400px;
  }
  @media only screen and (max-width: 480px) {
    width: 300px;
  }
`;

const DemoLongTable = styled.table`
  width: 100%;
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

const DemoLongTable1024 = styled.table`
  width: 100%;
  @media only screen and (min-width: 769px) {
    display: none;
  }
`;

const DemoLongTableTd480 = styled.td`
  @media only screen and (max-width: 480px) {
    display: none;
  }
`;

const DemoLongTableDiv = styled.div`
  margin-right: 0px;
  @media only screen and (max-width: 1024px) {
    margin-right: 0px;
  }
`;

const DemoLongTableH1 = styled.h1`
  font-size: 32px;
  margin-bottom: 20px;
  white-space: pre-line;
  @media only screen and (max-width: 1024px) {
    font-size: 28px;
  }
  @media only screen and (max-width: 900px) {
    font-size: 26px;
  }
`;

const DemoLongDiv = styled.div`
  background-color: #413d45;
  margin: 120px 50px;
  border-radius: 15px;
  padding: 50px 80px;
  color: white;
  @media only screen and (max-width: 1024px) {
    margin: 120px 50px;
    padding: 50px;
  }
  @media only screen and (max-width: 768px) {
    margin: 80px 20px;
    padding: 40px;
  }
  @media only screen and (max-width: 480px) {
    margin: 80px 20px;
    padding: 20px;
  }
`;

const DemoLongAudio = styled.audio`
  display: table;
  margin: 0px auto;
  padding-top: 15px;
  padding-bottom: 30px;
  width: 500px;
  @media only screen and (max-width: 768px) {
    width: 90%;
  }
  @media only screen and (max-width: 480px) {
    width: 90%;
  }
`;

const AppDemoTitle = styled.h1`
  font-size: 60px;
  white-space: pre-line;
  @media only screen and (max-width: 1200px) {
    font-size: 50px;
  }
  @media only screen and (max-width: 768px) {
    font-size: 40px;
  }
  @media only screen and (max-width: 480px) {
    font-size: 30px;
  }
`;

const AppDemoDiv = styled.div`
  margin-left: 100px;
  margin-right: 100px;
  @media only screen and (max-width: 1200px) {
    margin-left: 80px;
    margin-right: 80px;
  }
  @media only screen and (max-width: 1024px) {
    margin-left: 20px;
    margin-right: 20px;
  }
  @media only screen and (max-width: 768px) {
    margin-left: 20px;
    margin-right: 20px;
  }
  @media only screen and (max-width: 480px) {
    margin-left: 20px;
    margin-right: 20px;
  }
`;

const AppDemoImage = styled.img`
  width: 250px;
  margin-left: 35px;
  margin-right: 35px;
  border-radius: 27px;
  @media only screen and (max-width: 1200px) {
    width: 200px;
    margin-left: 30px;
    margin-right: 30px;
  }
  @media only screen and (max-width: 1024px) {
    width: 200px;
    margin-left: 20px;
    margin-right: 20px;
  }
  @media only screen and (max-width: 768px) {
    width: 172px;
    margin-left: 20px;
    margin-right: 20px;
  }
  @media only screen and (max-width: 480px) {
    width: 150px;
    margin-left: 10px;
    margin-right: 10px;
  }
`;

const AppDemoImage2 = styled.img`
  width: 250px;
  margin-left: 35px;
  margin-right: 35px;
  border-radius: 27px;
  @media only screen and (max-width: 1200px) {
    width: 200px;
    margin-left: 30px;
    margin-right: 30px;
  }
  @media only screen and (max-width: 1024px) {
    width: 200px;
    margin-left: 20px;
    margin-right: 20px;
  }
  @media only screen and (max-width: 768px) {
    display: none;
    width: 200px;
  }
  @media only screen and (max-width: 480px) {
    display: none;
    width: 200px;
  }
`;

const ChillfulActivityListStyle1Margin = styled.div`
  width: 100px;
  @media only screen and (max-width: 1300px) {
    width: 50px;
  }
  @media only screen and (max-width: 900px) {
    width: 20px;
  }
`;
const ChillfulActivityListStyle1Margin2 = styled.div`
  width: 15px;
`;
const ChillfulActivityListStyle1Td = styled.td`
  min-width: 350px;
  max-width: 350px;
`;
const ChillfulActivityListStyle1Table = styled.table`
  width: 100%;
`;

const ChillfulActivityListStyle1 = styled.div`
  overflow-x: scroll;
  height: 500px;
  padding-top: 70px;
  ::-webkit-scrollbar {
    display: none;
  }
`;
const ChillfulActivityListStyle1Title = styled.span`
  font-weight: 600;
  font-size: 24px;
`;
const ChillfulActivityListStyle1TitleDiv = styled.div`
  position: absolute;
  left: 100px;
  @media only screen and (max-width: 1300px) {
    left: 50px;
  }
  @media only screen and (max-width: 900px) {
    left: 20px;
  }
`;
const ChillfulMargin40 = styled.div`
  height: 40px;
`;

const MindfulDiv = styled.div`
  margin-left: 100px;
  margin-right: 100px;
  @media only screen and (max-width: 1200px) {
    margin-left: 80px;
    margin-right: 80px;
  }
  @media only screen and (max-width: 1024px) {
    margin-left: 20px;
    margin-right: 20px;
  }
  @media only screen and (max-width: 768px) {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: -30px;
  }
  @media only screen and (max-width: 480px) {
    margin-left: 20px;
    margin-right: 20px;
  }
`;

const MindfulDivHeader = styled.h1`
  text-align: center;
  font-size: 45px;
  margin-bottom: 10px;
  @media only screen and (max-width: 1024px) {
    font-size: 40px;
    margin-top: -30px;
  }
  @media only screen and (max-width: 768px) {
    font-size: 40px;
    text-align: left;
    margin-top: -60px;
  }
  @media only screen and (max-width: 480px) {
    font-size: 30px;
    text-align: left;
  }
`;

const MindfulDivSubHeader = styled.p`
  text-align: center;
  font-size: 24px;
  margin-bottom: 60px;
  margin-left: 15vw;
  margin-right: 15vw;
  @media only screen and (max-width: 1024px) {
    font-size: 20px;
    margin-bottom: 50px;
  }
  @media only screen and (max-width: 768px) {
    font-size: 18px;
    margin-left: 0vw;
    margin-right: 5vw;
    text-align: left;
    margin-bottom: 25px;
  }
  @media only screen and (max-width: 480px) {
    font-size: 16px;
    text-align: left;
    margin-bottom: 25px;
  }
`;

function HomePage() {
  const { t, i18n } = useTranslation();

  function toChillful() {
    $('html, body').animate(
      {
        scrollTop: $('#demoScroll').offset().top,
      },
      1000,
    );
  }

  function toActivities() {
    window.location.href = '/Activities';
  }

  function toMeditation() {
    window.location.href = '/Meditation';
  }

  function toAtoZ() {
    window.location.href = '/AtoZ';
  }

  function toInstagram() {
    window.location.href = 'https://www.instagram.com/vivablee/';
  }

  function toDownloadAppIOS() {
    window.location.href = 'https://apps.apple.com/hk/app/vivablee/id1551773278';
  }
  function toDownloadAppAndroid() {
    window.location.href = 'https://play.google.com/store/apps/details?id=com.vivableeraw';
  }

  function showMeditation(meditation_id) { }

  const [guidedMeditationTitle1, setGuidedMeditationTitle1] = useState('');
  const [guidedMeditationDescription1, setGuidedMeditationDescription1] = useState('');
  const [guidedMeditationCategory1, setGuidedMeditationCategory1] = useState('');
  const [guidedMeditationDuration1, setGuidedMeditationDuration1] = useState('');
  const [guidedMeditationSubCategory1, setGuidedMeditationSubCategory1] = useState('');
  const [guidedMeditationImagesLarge1, setGuidedMeditationImagesLarge1] = useState('');
  const [guidedMeditationImagesSmall1, setGuidedMeditationImagesSmall1] = useState('');

  const [guidedMeditationTitle2, setGuidedMeditationTitle2] = useState('');
  const [guidedMeditationDescription2, setGuidedMeditationDescription2] = useState('');
  const [guidedMeditationCategory2, setGuidedMeditationCategory2] = useState('');
  const [guidedMeditationDuration2, setGuidedMeditationDuration2] = useState('');
  const [guidedMeditationSubCategory2, setGuidedMeditationSubCategory2] = useState('');
  const [guidedMeditationImagesLarge2, setGuidedMeditationImagesLarge2] = useState('');
  const [guidedMeditationImagesSmall2, setGuidedMeditationImagesSmall2] = useState('');

  const [guidedMeditationTitle3, setGuidedMeditationTitle3] = useState('');
  const [guidedMeditationDescription3, setGuidedMeditationDescription3] = useState('');
  const [guidedMeditationCategory3, setGuidedMeditationCategory3] = useState('');
  const [guidedMeditationDuration3, setGuidedMeditationDuration3] = useState('');
  const [guidedMeditationSubCategory3, setGuidedMeditationSubCategory3] = useState('');
  const [guidedMeditationImagesLarge3, setGuidedMeditationImagesLarge3] = useState('');
  const [guidedMeditationImagesSmall3, setGuidedMeditationImagesSmall3] = useState('');

  const [guidedMeditationTitle4, setGuidedMeditationTitle4] = useState('');
  const [guidedMeditationDescription4, setGuidedMeditationDescription4] = useState('');
  const [guidedMeditationCategory4, setGuidedMeditationCategory4] = useState('');
  const [guidedMeditationDuration4, setGuidedMeditationDuration4] = useState('');
  const [guidedMeditationSubCategory4, setGuidedMeditationSubCategory4] = useState('');
  const [guidedMeditationImagesLarge4, setGuidedMeditationImagesLarge4] = useState('');
  const [guidedMeditationImagesSmall4, setGuidedMeditationImagesSmall4] = useState('');
  //const [isPopup, setIsPopup] = useState(false);

  useEffect(() => {
    db.collection('mindfulGuidedMeditation')
      .doc('DhvxuE08qwDG0gD0vdf5')
      .get()
      .then((doc) => {
        const data = doc.data();
        setGuidedMeditationTitle1(data['title_chi']);
        setGuidedMeditationDescription1(data['description_chi']);
        setGuidedMeditationCategory1(data['category_chi']);
        setGuidedMeditationDuration1(Math.round(data['duration'] / 60000));
        setGuidedMeditationSubCategory1(data['subCategory_chi']);
        setGuidedMeditationImagesLarge1(data['imagesLarge']);
        setGuidedMeditationImagesSmall1(data['imagesSmall']);
      });

    db.collection('mindfulGuidedMeditation')
      .doc('q8SfxV4oyuVCf0aUnz3i')
      .get()
      .then((doc) => {
        const data = doc.data();
        setGuidedMeditationTitle2(data['title_chi']);
        setGuidedMeditationDescription2(data['description_chi']);
        setGuidedMeditationCategory2(data['category_chi']);
        setGuidedMeditationDuration2(Math.round(data['duration'] / 60000));
        setGuidedMeditationSubCategory2(data['subCategory_chi']);
        setGuidedMeditationImagesLarge2(data['imagesLarge']);
        setGuidedMeditationImagesSmall2(data['imagesSmall']);
      });

    db.collection('mindfulGuidedMeditation')
      .doc('0v83LZbHXwe3pGb269ol')
      .get()
      .then((doc) => {
        const data = doc.data();
        setGuidedMeditationTitle3(data['title_chi']);
        setGuidedMeditationDescription3(data['description_chi']);
        setGuidedMeditationCategory3(data['category_chi']);
        setGuidedMeditationDuration3(Math.round(data['duration'] / 60000));
        setGuidedMeditationSubCategory3(data['subCategory_chi']);
        setGuidedMeditationImagesLarge3(data['imagesLarge']);
        setGuidedMeditationImagesSmall3(data['imagesSmall']);
      });

    db.collection('mindfulGuidedMeditation')
      .doc('ezFCn8PgiHBClil3rFqz')
      .get()
      .then((doc) => {
        const data = doc.data();
        setGuidedMeditationTitle4(data['title_chi']);
        setGuidedMeditationDescription4(data['description_chi']);
        setGuidedMeditationCategory4(data['category_chi']);
        setGuidedMeditationDuration4(Math.round(data['duration'] / 60000));
        setGuidedMeditationSubCategory4(data['subCategory_chi']);
        setGuidedMeditationImagesLarge4(data['imagesLarge']);
        setGuidedMeditationImagesSmall4(data['imagesSmall']);
      });
  }, []);

  $('#testContainer11').hover(
    function () {
      $('#test11').css('transform', 'scale(1.2)');
    },
    function () {
      $('#test11').css('transform', 'scale(1)');
    },
  );
  $('#testContainer12').hover(
    function () {
      $('#test12').css('transform', 'scale(1.2)');
    },
    function () {
      $('#test12').css('transform', 'scale(1)');
    },
  );
  $('#testContainer13').hover(
    function () {
      $('#test13').css('transform', 'scale(1.2)');
    },
    function () {
      $('#test13').css('transform', 'scale(1)');
    },
  );
  $('#testContainer14').hover(
    function () {
      $('#test14').css('transform', 'scale(1.2)');
    },
    function () {
      $('#test14').css('transform', 'scale(1)');
    },
  );
  $('#testContainer15').hover(
    function () {
      $('#test15').css('transform', 'scale(1.2)');
    },
    function () {
      $('#test15').css('transform', 'scale(1)');
    },
  );
  $('#testContainer16').hover(
    function () {
      $('#test16').css('transform', 'scale(1.2)');
    },
    function () {
      $('#test16').css('transform', 'scale(1)');
    },
  );
  /*
    function toApp() {
      window.location.href = '/app/';
    }
  */
  const ref = useRef();
  const openTooltip = () => ref.current.open();

  return (
    <div>
      <div style={{ width: '100%' }}>
        <Menu />
        <PopupModal Ref={ref} />

        <div style={{ backgroundColor: '#F4F2EC' }}>
          <Carousel
            emulateTouch={true}
            interval={3000}
            autoPlay={true}
            showThumbs={false}
            showStatus={false}
            infiniteLoop={true}
            renderIndicator={(onClickHandler, isSelected, index, label) => {
              return (
                // this is styled component.
                <div
                  selected={isSelected}
                  onClick={onClickHandler}
                  style={{
                    marginLeft: 8,
                    marginRight: 8,
                    cursor: 'pointer',
                    height: 10,
                    width: 10,
                    borderRadius: 20,
                    backgroundColor: isSelected ? '#FF9700' : 'white',
                    display: 'inline-block',
                  }}
                ></div>
              );
            }}
          >
            <TopDiv>
              <TopDivContain>
                <TopHeaderTop>{t('homeHeaderTop')}</TopHeaderTop>
                <TopHeader>{t('homeHeader')}</TopHeader>
                <TopPara>{t('homeSubHeader')}</TopPara>
                <BannerBtn onClick={toChillful}>{t('homeBtn')}</BannerBtn>
                <br />
                <div>
                  <BannerBtn2 onClick={toInstagram}>
                    <img
                      style={{ width: '10px', marginLeft: '1px', marginTop: 6 }}
                      src={require('../images/general/play_white.png')}
                      alt="icon"
                    ></img>
                  </BannerBtn2>
                  <span
                    style={{
                      marginLeft: '12px',
                      fontSize: '14px',
                      transitionProperty: 'none',
                      color: 'black',
                    }}
                  >
                    {t('homeToInstagram')}
                  </span>
                </div>
              </TopDivContain>

              <TopImageDiv>
                <TopImage src={require('../images/main/app1.png')}></TopImage>
              </TopImageDiv>
            </TopDiv>

            <TopDiv>
              <TopDivContain>
                <TopHeaderTop>{t('chillAtHomeHeaderTop')}</TopHeaderTop>
                <TopHeader>{t('chillAtHomeHeader')}</TopHeader>
                <TopPara>{t('chillAtHomeSubHeader')}</TopPara>
                <BannerBtn onClick={toActivities}>{t('chillAtHomeBtn')}</BannerBtn>
                <br />
                <div>
                  <BannerBtn2 onClick={toInstagram}>
                    <img
                      style={{ width: '10px', marginLeft: '1px', marginTop: 6 }}
                      src={require('../images/general/play_white.png')}
                      alt="icon"
                    ></img>
                  </BannerBtn2>
                  <span
                    style={{
                      marginLeft: '12px',
                      fontSize: '14px',
                      transitionProperty: 'none',
                      color: 'black',
                    }}
                  >
                    {t('chillAtHomeToInstagram')}
                  </span>
                </div>
              </TopDivContain>

              <TopImageDiv>
                <TopImage src={require('../images/main/app1.png')}></TopImage>
              </TopImageDiv>
            </TopDiv>

            <TopDiv>
              <TopDivContain>
                <TopHeaderTop>{t('beMindfulHeaderTop')}</TopHeaderTop>
                <TopHeader>{t('beMindfulHeader')}</TopHeader>
                <TopPara>{t('beMindfulSubHeader')}</TopPara>
                <BannerBtn onClick={toMeditation}>{t('beMindfulBtn')}</BannerBtn>
                <br />
                <div>
                  <BannerBtn2 onClick={toInstagram}>
                    <img
                      style={{ width: '10px', marginLeft: '1px', marginTop: 6 }}
                      src={require('../images/general/play_white.png')}
                      alt="icon"
                    ></img>
                  </BannerBtn2>
                  <span
                    style={{
                      marginLeft: '12px',
                      fontSize: '14px',
                      transitionProperty: 'none',
                      color: 'black',
                    }}
                  >
                    {t('beMindfulToInstagram')}
                  </span>
                </div>
              </TopDivContain>

              <TopImageDiv>
                <TopImage src={require('../images/main/app1.png')}></TopImage>
              </TopImageDiv>
            </TopDiv>
          </Carousel>
        </div>

        <div id="demoScroll"></div>

        <svg
          width="100%"
          //height="auto"
          viewBox="0 0 3552 261"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          style={{ marginTop: -1 }}
        >
          <title>Grey Overlay</title>
          <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g
              id="Stage-Card"
              transform="translate(1365.500000, 1.000000) scale(-1, 1) translate(-1365.500000, -1.000000) translate(-821.000000, -259.000000)"
              fill="#F4F2EC"
            >
              <path
                d="M0,259.839582 L0,389.919791 C327.839308,303.199652 646.204645,259.839582 955.096011,259.839582 C1427.71748,259.839582 2114.31899,520 2587.43566,520 C2905.41919,520 3226.94064,476.63993 3552,389.919791 L3552,259.839582 L0,259.839582 Z"
                id="Grey-Overlay"
              ></path>
            </g>
          </g>
        </svg>

        <ChillfulActivityListStyle2 id="browseArticles">
          <ArticleTitleDiv>
            <ArticleTitleTop>{t('homeActivityHeaderTop')}</ArticleTitleTop>
            <ArticleTitle>{t('homeActivityHeader')}</ArticleTitle>
          </ArticleTitleDiv>

          <Margin20To40 />

          <ChillfulActivityListStyle1>
            <ChillfulActivityListStyle1TitleDiv>
              <ChillfulActivityListStyle1Title>
                {t('homeActivitySubHeader1')}
              </ChillfulActivityListStyle1Title>
              <ChillfulActivityListStyle2Arrow
                src={require('../images/general/arrow_right_black.png')}
              ></ChillfulActivityListStyle2Arrow>
            </ChillfulActivityListStyle1TitleDiv>
            <ChillfulMargin40 />

            <ChillfulActivityListStyle1Table>
              <tbody>
                <tr>
                  <td>
                    <ChillfulActivityListStyle1Margin />
                  </td>
                  <ChillfulActivityListStyle1Td>
                    <ActivityCard shadow={true} id={'test1'} activity_id={'0PypOA49bgR2a5b0wzZb'} />
                  </ChillfulActivityListStyle1Td>
                  <td>
                    <ChillfulActivityListStyle1Margin2 />
                  </td>
                  <ChillfulActivityListStyle1Td>
                    <ActivityCard shadow={true} id={'test2'} activity_id={'0VPruk5zfWXA7ackAYr9'} />
                  </ChillfulActivityListStyle1Td>
                  <td>
                    <ChillfulActivityListStyle1Margin2 />
                  </td>
                  <ChillfulActivityListStyle1Td>
                    <ActivityCard shadow={true} id={'test3'} activity_id={'0QfXEKabTvKP2dU2O59i'} />
                  </ChillfulActivityListStyle1Td>
                  <td>
                    <ChillfulActivityListStyle1Margin2 />
                  </td>
                  <ChillfulActivityListStyle1Td>
                    <ActivityCard shadow={true} id={'test4'} activity_id={'0Qx9mTpl6qnitrOgGhN6'} />
                  </ChillfulActivityListStyle1Td>
                  <td>
                    <ChillfulActivityListStyle1Margin2 />
                  </td>
                  <ChillfulActivityListStyle1Td>
                    <ActivityCard shadow={true} id={'test5'} activity_id={'0RqSIsYNIrP44H1ixXfV'} />
                  </ChillfulActivityListStyle1Td>
                  <td>
                    <ChillfulActivityListStyle1Margin />
                  </td>
                </tr>
              </tbody>
            </ChillfulActivityListStyle1Table>
          </ChillfulActivityListStyle1>

          <ChillfulActivityListStyle1 style={{ marginTop: -150 }}>
            <ChillfulActivityListStyle1TitleDiv>
              <ChillfulActivityListStyle1Title>
                {t('homeActivitySubHeader2')}
              </ChillfulActivityListStyle1Title>
              <ChillfulActivityListStyle2Arrow
                src={require('../images/general/arrow_right_black.png')}
              ></ChillfulActivityListStyle2Arrow>
            </ChillfulActivityListStyle1TitleDiv>
            <ChillfulMargin40 />

            <ChillfulActivityListStyle1Table>
              <tbody>
                <tr>
                  <td>
                    <ChillfulActivityListStyle1Margin />
                  </td>
                  <ChillfulActivityListStyle1Td>
                    <ActivityCard shadow={true} id={'test6'} activity_id={'82igyMKjFbohQqJp30zZ'} />
                  </ChillfulActivityListStyle1Td>
                  <td>
                    <ChillfulActivityListStyle1Margin2 />
                  </td>
                  <ChillfulActivityListStyle1Td>
                    <ActivityCard shadow={true} id={'test7'} activity_id={'DtcJee2ap2eHA7fPkIWA'} />
                  </ChillfulActivityListStyle1Td>
                  <td>
                    <ChillfulActivityListStyle1Margin2 />
                  </td>
                  <ChillfulActivityListStyle1Td>
                    <ActivityCard shadow={true} id={'test8'} activity_id={'9nJ0oYIfItozPSx1EDMS'} />
                  </ChillfulActivityListStyle1Td>
                  <td>
                    <ChillfulActivityListStyle1Margin2 />
                  </td>
                  <ChillfulActivityListStyle1Td>
                    <ActivityCard shadow={true} id={'test9'} activity_id={'AJfCx6bE5QhgzsBm2e5G'} />
                  </ChillfulActivityListStyle1Td>
                  <td>
                    <ChillfulActivityListStyle1Margin2 />
                  </td>
                  <ChillfulActivityListStyle1Td>
                    <ActivityCard shadow={true} id={'test10'} activity_id={'Ad5IRMIjIowLup1z2068'} />
                  </ChillfulActivityListStyle1Td>
                  <td>
                    <ChillfulActivityListStyle1Margin />
                  </td>
                </tr>
              </tbody>
            </ChillfulActivityListStyle1Table>
          </ChillfulActivityListStyle1>
        </ChillfulActivityListStyle2>

        <MindfulDiv>
          <MindfulDivHeader>{t('homeMindfulHeader')}</MindfulDivHeader>
          <MindfulDivSubHeader>{t('homeMindfulSubHeader')}</MindfulDivSubHeader>

          <ChillfulActivityListStyle2>
            <div>
              <table style={{ width: '100%' }} cellSpacing="0" cellPadding="0">
                <colgroup>
                  <ChillfulColWidth50 />
                  <ChillfulColWidth25Long2 />
                  <ChillfulColWidth25Long />
                </colgroup>
                <tbody>
                  <tr>
                    <td rowSpan="2">
                      <ChillfulActivityListStyle2ContainerDiv1
                        id={'testContainer11'}
                        style={{ borderRadius: 30 }}
                        onClick={isDesktop ? openTooltip : (isIOS ? toDownloadAppIOS : toDownloadAppAndroid)}
                      >
                        <ChillfulActivityListStyle2Container>
                          <ChillfulActivityListStyle2ImageCover
                            id={'test11'}
                            style={{ backgroundImage: 'url(' + guidedMeditationImagesLarge1 + ')' }}
                          ></ChillfulActivityListStyle2ImageCover>
                        </ChillfulActivityListStyle2Container>
                        <ChillfulActivityListStyle2ClassBottom>
                          <ChillfulActivityListIcon
                            src={require('../images/general/mindful_icon_original.png')}
                          ></ChillfulActivityListIcon>
                          <MindfulListStyle2ClassBottomText1>
                            {guidedMeditationCategory1}
                          </MindfulListStyle2ClassBottomText1>
                          <ChillfulActivityListStyle2ClassBottomText2>
                            {guidedMeditationTitle1}
                          </ChillfulActivityListStyle2ClassBottomText2>
                          <ChillfulActivityListStyle2ClassBottomText3>
                            {guidedMeditationDescription1}
                          </ChillfulActivityListStyle2ClassBottomText3>
                          <ChillfulActivityListStyle2ClassBottomText4>
                            {guidedMeditationSubCategory1} ● {guidedMeditationDuration1} 分鐘
                        </ChillfulActivityListStyle2ClassBottomText4>
                        </ChillfulActivityListStyle2ClassBottom>
                      </ChillfulActivityListStyle2ContainerDiv1>
                    </td>

                    <ChillfulActivityListStyle2ContainerTd>
                      <ChillfulActivityListStyle2ContainerDiv2
                        id={'testContainer12'}
                        style={{ borderRadius: 30 }}
                        onClick={isDesktop ? openTooltip : (isIOS ? toDownloadAppIOS : toDownloadAppAndroid)}
                      >
                        <ChillfulActivityListStyle2Container>
                          <ChillfulActivityListStyle2ImageCover
                            id={'test12'}
                            style={{ backgroundImage: 'url(' + guidedMeditationImagesLarge2 + ')' }}
                          ></ChillfulActivityListStyle2ImageCover>
                        </ChillfulActivityListStyle2Container>
                        <ChillfulActivityListStyle2ClassBottom>
                          <ChillfulActivityListIcon
                            src={require('../images/general/mindful_icon_original.png')}
                          ></ChillfulActivityListIcon>
                          <MindfulListStyle2ClassBottomText1>
                            {guidedMeditationCategory2}
                          </MindfulListStyle2ClassBottomText1>
                          <ChillfulActivityListStyle2ClassBottomText2>
                            {guidedMeditationTitle2}
                          </ChillfulActivityListStyle2ClassBottomText2>
                          <ChillfulActivityListStyle2ClassBottomText3>
                            {guidedMeditationDescription2}
                          </ChillfulActivityListStyle2ClassBottomText3>
                          <ChillfulActivityListStyle2ClassBottomText4>
                            {guidedMeditationSubCategory2} ● {guidedMeditationDuration2} 分鐘
                        </ChillfulActivityListStyle2ClassBottomText4>
                        </ChillfulActivityListStyle2ClassBottom>
                      </ChillfulActivityListStyle2ContainerDiv2>
                    </ChillfulActivityListStyle2ContainerTd>

                    <ChillfulActivityListStyle2TdLong2>
                      <ChillfulActivityListStyle2Small1
                        id={'testContainer13'}
                        style={{ borderRadius: 30 }}
                        onClick={isDesktop ? openTooltip : (isIOS ? toDownloadAppIOS : toDownloadAppAndroid)}
                      >
                        <ChillfulActivityListStyle2Container2>
                          <ChillfulActivityListStyle2ImageCover
                            id={'test13'}
                            style={{ backgroundImage: 'url(' + guidedMeditationImagesLarge2 + ')' }}
                          ></ChillfulActivityListStyle2ImageCover>
                        </ChillfulActivityListStyle2Container2>
                        <ChillfulActivityListStyle2ClassBottom2>
                          <ChillfulActivityListIcon
                            src={require('../images/general/mindful_icon_original.png')}
                          ></ChillfulActivityListIcon>
                          <MindfulListStyle2ClassBottomText1>
                            {guidedMeditationCategory2}
                          </MindfulListStyle2ClassBottomText1>
                          <ChillfulActivityListStyle2ClassBottomSmallText2>
                            {guidedMeditationTitle2}
                          </ChillfulActivityListStyle2ClassBottomSmallText2>
                          <ChillfulActivityListStyle2ClassBottomText4>
                            {guidedMeditationSubCategory2} ● {guidedMeditationDuration2} 分鐘
                        </ChillfulActivityListStyle2ClassBottomText4>
                        </ChillfulActivityListStyle2ClassBottom2>
                      </ChillfulActivityListStyle2Small1>
                    </ChillfulActivityListStyle2TdLong2>

                    <ChillfulActivityListStyle2TdLong>
                      <ChillfulActivityListStyle2Small1
                        id={'testContainer14'}
                        style={{ borderRadius: 30 }}
                        onClick={isDesktop ? openTooltip : (isIOS ? toDownloadAppIOS : toDownloadAppAndroid)}
                      >
                        <ChillfulActivityListStyle2Container2>
                          <ChillfulActivityListStyle2ImageCover
                            id={'test14'}
                            style={{ backgroundImage: 'url(' + guidedMeditationImagesLarge3 + ')' }}
                          ></ChillfulActivityListStyle2ImageCover>
                        </ChillfulActivityListStyle2Container2>
                        <ChillfulActivityListStyle2ClassBottom2>
                          <ChillfulActivityListIcon
                            src={require('../images/general/mindful_icon_original.png')}
                          ></ChillfulActivityListIcon>
                          <MindfulListStyle2ClassBottomText1>
                            {guidedMeditationCategory3}
                          </MindfulListStyle2ClassBottomText1>
                          <ChillfulActivityListStyle2ClassBottomSmallText2>
                            {guidedMeditationTitle3}
                          </ChillfulActivityListStyle2ClassBottomSmallText2>
                          <ChillfulActivityListStyle2ClassBottomText4>
                            {guidedMeditationSubCategory3} ● {guidedMeditationDuration3} 分鐘
                        </ChillfulActivityListStyle2ClassBottomText4>
                        </ChillfulActivityListStyle2ClassBottom2>
                      </ChillfulActivityListStyle2Small1>
                    </ChillfulActivityListStyle2TdLong>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <ChillfulActivityListStyle2Small2
                        id={'testContainer15'}
                        style={{ borderRadius: 30 }}
                        onClick={isDesktop ? openTooltip : (isIOS ? toDownloadAppIOS : toDownloadAppAndroid)}
                      >
                        <ChillfulActivityListStyle2Container3>
                          <ChillfulActivityListStyle2ImageCover
                            id={'test15'}
                            style={{ backgroundImage: 'url(' + guidedMeditationImagesLarge4 + ')' }}
                          ></ChillfulActivityListStyle2ImageCover>
                        </ChillfulActivityListStyle2Container3>
                        <ChillfulActivityListStyle2ClassBottom>
                          <ChillfulActivityListIcon
                            src={require('../images/general/mindful_icon_original.png')}
                          ></ChillfulActivityListIcon>
                          <MindfulListStyle2ClassBottomText1>
                            {guidedMeditationCategory4}
                          </MindfulListStyle2ClassBottomText1>
                          <ChillfulActivityListStyle2ClassBottomText2>
                            {guidedMeditationTitle4}
                          </ChillfulActivityListStyle2ClassBottomText2>
                          <ChillfulActivityListStyle2ClassBottomText3>
                            {guidedMeditationDescription4}
                          </ChillfulActivityListStyle2ClassBottomText3>
                          <ChillfulActivityListStyle2ClassBottomText4>
                            {guidedMeditationSubCategory4} ● {guidedMeditationDuration4} 分鐘
                        </ChillfulActivityListStyle2ClassBottomText4>
                        </ChillfulActivityListStyle2ClassBottom>
                      </ChillfulActivityListStyle2Small2>

                      <ChillfulActivityListStyle2Small3
                        id={'testContainer16'}
                        style={{ borderRadius: 30 }}
                        onClick={isDesktop ? openTooltip : (isIOS ? toDownloadAppIOS : toDownloadAppAndroid)}
                      >
                        <ChillfulActivityListStyle2Container2>
                          <ChillfulActivityListStyle2ImageCover
                            id={'test16'}
                            style={{ backgroundImage: 'url(' + guidedMeditationImagesLarge3 + ')' }}
                          ></ChillfulActivityListStyle2ImageCover>
                        </ChillfulActivityListStyle2Container2>
                        <ChillfulActivityListStyle2ClassBottom2>
                          <ChillfulActivityListIcon
                            src={require('../images/general/mindful_icon_original.png')}
                          ></ChillfulActivityListIcon>
                          <MindfulListStyle2ClassBottomText1>
                            {guidedMeditationCategory3}
                          </MindfulListStyle2ClassBottomText1>
                          <ChillfulActivityListStyle2ClassBottomSmallText2>
                            {guidedMeditationTitle3}
                          </ChillfulActivityListStyle2ClassBottomSmallText2>
                          <ChillfulActivityListStyle2ClassBottomText4>
                            {guidedMeditationSubCategory3} ● {guidedMeditationDuration3} 分鐘
                        </ChillfulActivityListStyle2ClassBottomText4>
                        </ChillfulActivityListStyle2ClassBottom2>
                      </ChillfulActivityListStyle2Small3>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </ChillfulActivityListStyle2>
        </MindfulDiv>

        <DemoLongDiv>
          <DemoLongTable>
            <colgroup>
              <col style={{ width: '40%' }} />
              <col style={{ width: '60%' }} />
            </colgroup>
            <tbody>
              <tr>
                <td>
                  <DemoLongTableDiv>
                    <DemoLongTableH1>{t('homeMClosingHeader')}</DemoLongTableH1>
                    <div
                      style={{
                        backgroundColor: '#f4f2ec',
                        borderRadius: '15px',
                        color: '#444',
                        display: 'inline-block',
                        cursor: 'pointer',
                      }}
                    >
                      <div style={{ padding: '15px 20px 15px 20px', display: 'inline-block' }}>
                        <h1 onClick={toInstagram} style={{ fontSize: '20px', lineHeight: '20px' }}>
                          {t('homeMClosingBtn')}
                        </h1>
                      </div>
                    </div>
                  </DemoLongTableDiv>
                </td>
                <td style={{ width: '100%' }}>
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <div
                            style={{
                              width: '100%',
                              backgroundColor: '#f4f2ec',
                              borderRadius: '15px',
                              color: '#444',
                              marginRight: 20,
                              cursor: 'pointer',
                            }}
                          >
                            <div style={{ padding: '10px 10px 10px 10px' }}>
                              <img
                                src={require('../images/main/viva.jpg')}
                                style={{ width: '100%', borderRadius: 20 }}
                                alt="icon"
                              ></img>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div
                            style={{
                              width: '100%',
                              backgroundColor: '#f4f2ec',
                              borderRadius: '15px',
                              color: '#444',
                              marginLeft: 20,
                              cursor: 'pointer',
                            }}
                          >
                            <div style={{ padding: '10px 10px 10px 10px' }}>
                              <img
                                src={require('../images/main/viva2.jpg')}
                                style={{ width: '100%', borderRadius: 20 }}
                                alt="icon"
                              ></img>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </DemoLongTable>

          <DemoLongTable1024>
            <tbody>
              <tr>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <div
                            style={{
                              width: '100%',
                              backgroundColor: '#f4f2ec',
                              borderRadius: '15px',
                              color: '#444',
                              marginRight: 0,
                              cursor: 'pointer',
                            }}
                          >
                            <div style={{ padding: '10px 10px 10px 10px' }}>
                              <img
                                src={require('../images/main/viva.jpg')}
                                style={{ width: '100%', borderRadius: 20 }}
                                alt="icon"
                              ></img>
                            </div>
                          </div>
                        </td>
                        <DemoLongTableTd480>
                          <div style={{ height: 20 }}></div>
                        </DemoLongTableTd480>
                        <DemoLongTableTd480>
                          <div
                            style={{
                              width: '100%',
                              backgroundColor: '#f4f2ec',
                              borderRadius: '15px',
                              color: '#444',
                              marginLeft: 0,
                              cursor: 'pointer',
                            }}
                          >
                            <div style={{ padding: '10px 10px 10px 10px' }}>
                              <img
                                src={require('../images/main/viva2.jpg')}
                                style={{ width: '100%', borderRadius: 20 }}
                                alt="icon"
                              ></img>
                            </div>
                          </div>
                        </DemoLongTableTd480>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <div style={{ marginRight: '100px', marginTop: 25 }}>
                    <h1 style={{ fontSize: '24px', marginBottom: '20px' }}>
                      {t('homeMClosingHeader')}
                    </h1>
                  </div>
                </td>
              </tr>
              <tr>
                <td style={{ width: '100%' }}>
                  <div
                    style={{
                      backgroundColor: '#f4f2ec',
                      borderRadius: '15px',
                      color: '#444',
                      display: 'inline-block',
                    }}
                  >
                    <div style={{ padding: '12px 16px 12px 16px', display: 'inline-block' }}>
                      <h1
                        onClick={toInstagram}
                        style={{ fontSize: '16px', lineHeight: '16px', cursor: 'pointer' }}
                      >
                        {t('homeMClosingBtn')}
                      </h1>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </DemoLongTable1024>
        </DemoLongDiv>

        <svg
          width="100%"
          //height="auto"
          viewBox="0 0 3840 260"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <title>Path</title>
          <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path
              d="M-9.09494702e-13,0.5 L-9.09494702e-13,259.5 C543.289571,86.8333333 1184.99624,0.5 1925.12,0.5 C2499.39149,0.5 3137.68482,86.8333333 3840,259.5 L3840,0.5 L-9.09494702e-13,0.5 Z"
              id="Path"
              fill="#413D45"
              transform="translate(1920.000000, 130.000000) scale(1, -1) translate(-1920.000000, -130.000000) "
            ></path>
          </g>
        </svg>
        <div
          style={{
            backgroundColor: '#413d45',
            marginTop: '-7px',
            textAlign: 'center',
            color: 'white',
            paddingBottom: '80px',
          }}
        >
          <div style={{ height: '150px' }}></div>

          <AppDemoDiv>
            <AppDemoTitle>{t('homeDownloadAppHeader')}</AppDemoTitle>
            <div style={{ marginTop: '100px' }}>
              <AppDemoImage src={require('../images/main/app1.png')}></AppDemoImage>
              <AppDemoImage src={require('../images/main/app2.png')}></AppDemoImage>
              <AppDemoImage2 src={require('../images/main/app3.png')}></AppDemoImage2>
            </div>
            <h1 style={{ fontSize: '40px', marginTop: '100px' }}>{t('homeDownloadAppDownload')}</h1>

            <p style={{ fontSize: '12px', width: '70%', margin: '10px auto' }}>
              {t('homeDownloadAppDownloadNote')}
            </p>

            <div style={{ marginTop: '80px', position: 'relative' }}>
              <div
                style={{
                  left: '50%',
                  marginRight: '-50%',
                  position: 'absolute',
                  transform: 'translate(-50%, -50%)',
                }}
              >
                <BannerBtn onClick={isDesktop ? openTooltip : (isIOS ? toDownloadAppIOS : toDownloadAppAndroid)}>{t('homeDownloadAppDownloadBtn')}</BannerBtn>
              </div>
            </div>
          </AppDemoDiv>
        </div>

        <svg
          width="100%"
          //height="auto"
          viewBox="0 0 3840 259"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <title>Path</title>
          <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path
              d="M0,0 C539.876237,172.666667 1179.87624,259 1920,259 C2494.27149,259 3134.27149,172.666667 3840,0 L0,0 Z"
              id="Path"
              fill="#413D45"
            ></path>
          </g>
        </svg>

        <Footer />
      </div>
    </div>
  );
}

export default HomePage;
