import React, { useState, useEffect, useRef } from 'react';
import Menu from '../components/Menu';
import Footer from '../components/Footer';
//import { Link } from 'react-router-dom';
import styled from 'styled-components'; // package to define css class
import $ from 'jquery'; // package to run jQuery
import { db } from '../firebase.js';
//import { storage, db } from '../firebase';
//import SelectSearch from 'react-select-search';
//import { useTranslation } from 'react-i18next';
import './AtoZ.css';
//import * as HtmlToReact from 'html-to-react';
import img from '../images/atoz/atozSmile.jpg';
//import AtozPage from './AtozPage';
//import { useParams } from 'react-router-dom';
import Skeleton from '@material-ui/lab/Skeleton';
//import { lighten } from '@material-ui/core';

const AtoZBanner = styled.div`
  height: 600px;
  background-size: cover;
  background-image: url(${img});
  background-position: center;
  border-radius: 30px;
`;

const BannerBtn = styled.button`
  padding: 20px 35px;
  color: white;
  border-radius: 50px;
  border-style: none;
  background-color: #ff9700;
  font-weight: 700;
  font-size: 16px;
  box-shadow: 0px 3px 12px 2px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  margin-top: 30px;
`;

const AtoZHr = styled.hr`
  background-color: #555;
  border: none;
  height: 1px;
  margin-bottom: 1rem;
  margin-top: 1rem;
`;
/*
//header1 style
const AtoZHeader1 = styled.div`
    margin-top: 30px;
    color: #333;
    font-weight: 1000;
    font-size: 50px;
    margin-left: 0px;
`;

//header2 style
const AtoZHeader2 = styled.div`
    margin-top: 10px;
    color: #333;
    font-weight: 500;
    font-size: 30px;
    margin-left: 0px;
`;

//selection style
const AtoZSelector = styled.div`
    margin-top: 30px;
`;

//linker style
const AtoZLinker = styled.div`
    margin-top: 30px;
`;
*/
const AtoZBannerText = styled.div`
  margin-right: 100px;
  transition: all 0.5s;
  @media only screen and (max-width: 1024px) {
    margin-right: 50px;
  }
  @media only screen and (max-width: 768px) {
    margin-right: 30px;
  }
`;

const AtoZBannerText1 = styled.span`
  margin-left: 10px;
  color: #ff9700;
  font-weight: 800;
  font-size: 24px;
  transition: all 0.5s;
  @media only screen and (max-width: 800px) {
    font-size: 18px;
  }
`;

const AtoZBannerText2 = styled.p`
  font-weight: 600;
  font-size: 50px;
  margin-top: 10px;
  color: #444;
  transition: all 0.5s;
  max-width: 700px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  @media only screen and (max-width: 800px) {
    font-size: 36px;
  }
`;
/*
const AtoZBannerText3 = styled.p`
  font-weight: 500;
  font-size: 24px;
  margin-top: 5px;
  max-width: 500px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  transition: all 0.5s;
  @media only screen and (max-width: 800px) {
    font-size: 20px;
  }
`;

const AtoZTitleDiv = styled.p`
  font-weight: 500;
  font-size: 24px;
  margin-top: 5px;
  @media only screen and (max-width: 800px) {
    font-size: 20px;
  }
`;
*/
const AtoZBannerIcon = styled.img`
height: 24px;
transition: all 0.5s;
@media only screen and (max-width: 800px) {
  height: 18px;
}
`;

const AtoZTopBannerDiv = styled.div`
padding: 50px 150px 100px 150px;
@media only screen and (max-width: 1280px) {
  padding: 50px 100px 100px 100px;
}
@media only screen and (max-width: 1024px) {
  padding: 50px 70px 100px 70px;
}
@media only screen and (max-width: 768px) {
  padding: 50px 20px 100px 20px;
}
`;

const AtoZContentDiv = styled.div`
  padding: 50px 200px 100px 200px;
  @media only screen and (max-width: 1280px) {
    padding: 50px 100px 100px 100px;
  }
  @media only screen and (max-width: 1024px) {
    padding: 50px 70px 100px 70px;
  }
  @media only screen and (max-width: 768px) {
    padding: 50px 20px 100px 20px;
  }
`;

const AtoZTable = styled.table`
  width: 100%;
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

const AtoZTable768 = styled.table`
  width: 100%;
  @media only screen and (min-width: 769px) {
    display: none;
  }
`;

const AtoZMenu = styled.ul`
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

const AtoZMenu768 = styled.ul`
  display: flex;
  overflow-x: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
  @media only screen and (min-width: 769px) {
    display: none;
  }
`;

const AtoZTopBannerTable = styled.table`
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

const AtoZTopBannerTable768 = styled.table`
  @media only screen and (min-width: 769px) {
    display: none;
  }
`;

function AtoZ() {
  //const { t, i18n } = useTranslation();
  const [optionList, setOptionList] = useState([]);
  //const { page } = useParams();
  const [tempOptionList, setTempOptionList] = useState([]);
  const [selectedLetter, setSelectedLetter] = useState("ALL");
  //const [selectedButton, setSelectedButton] = useState(false);
  //const [emptyList, setEmptyList] = useState([]);
  const [disable, setDisable] = useState(false);
  const alphabet = ["ALL", "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K",
    "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];
  const [loading, setLoading] = useState(true);

  //const [executing, setExecuting] = useState(false);

  //const { onClick, disabled } = props

  // const onRealClick = async (event) => {
  //   setExecuting(true);
  //   try {
  //     await onClick();
  //   } finally {
  //     setExecuting(false);
  //   }
  // };


  //const htmlToReactParser = new HtmlToReact.Parser();

  useEffect(() => {
    db.collection("atoz")
      .orderBy("category")
      .get()
      .then(function (querySnapshot) {
        querySnapshot.docs.forEach((doc) => {
          if (doc.data()['validateFlag']) {
            setOptionList((optionList) => [
              ...optionList,
              {
                option_id: doc.id,
                option_title_chi: doc.data()['title_chi'],
                option_title: doc.data()['title'],
                option_content: doc.data()['content'],
                option_category: doc.data()['category'],
              }
            ]);
          }
        });
      });
  }, []);


  useEffect(() => {
    setTempOptionList([]);
    if (selectedLetter != "ALL") {
      db.collection("atoz")
        .where('category', '==', selectedLetter)
        .get()
        .then(function (querySnapshot) {
          querySnapshot.docs.forEach((doc) => {
            if (doc.data()['validateFlag']) {
              setTempOptionList((tempOptionList) => [
                ...tempOptionList,
                {
                  option_title_chi: doc.data()['title_chi'],
                  option_title: doc.data()['title'],
                  option_content: doc.data()['content'],
                  option_category: doc.data()['category']
                }
              ]);
            }
          });
        })
        .then(() => { setLoading(false) });
    }
    else {
      db.collection("atoz")
        .orderBy("category")
        .get()
        .then(function (querySnapshot) {
          querySnapshot.docs.forEach((doc) => {
            if (doc.data()['validateFlag']) {
              setTempOptionList((tempOptionList) => [
                ...tempOptionList,
                {
                  option_title_chi: doc.data()['title_chi'],
                  option_title: doc.data()['title'],
                  option_content: doc.data()['content'],
                  option_category: doc.data()['category']
                }
              ]);
            }
          });
        })
        .then(() => { setLoading(false) });
    }
  }, [selectedLetter]);

  function toAtozDetail() {
    $('html, body').animate(
      {
        scrollTop: $('#demoScroll').offset().top,
      },
      1000,
    );
  }

  function isEmptyAtoZ(letter) {
    let count = 0;
    optionList.map((item, i) => {
      if (item.option_category == letter)
        count++;
    })
    if (count > 0 || letter === "ALL")
      return false;
    return true;
  }
  /*
    function emptyList(letter) {
      tempOptionList.map((item, i) => {
        if (item)
          if (item.option_category == letter)
            count++;
      })
    }
  */
  return (
    <div style={{ width: '100%' }}>
      <Menu page={'atoz'} />

      {/** Be AtoZ top banner */}
      <center>
        <AtoZTopBannerDiv>
          <AtoZTopBannerTable>
            <colgroup>
              <col style={{ width: '50%' }} />
              <col style={{ width: '50%' }} />
            </colgroup>
            <tbody>
              <tr>
                <td>
                  <AtoZBannerText>
                    <AtoZBannerIcon
                      style={{ marginBottom: '-3px' }}
                      src={require('../images/general/mindful_icon_original.png')}
                    ></AtoZBannerIcon>
                    <AtoZBannerText1>
                      Vivablee A to Z
                  </AtoZBannerText1>
                    <AtoZBannerText2>
                      認識心理健康及冥想
                  </AtoZBannerText2>
                    <BannerBtn onClick={toAtozDetail}>了解更多</BannerBtn>
                  </AtoZBannerText>
                </td>
                <td>
                  <AtoZBanner />
                </td>
              </tr>
            </tbody>
          </AtoZTopBannerTable>
          <AtoZTopBannerTable768 style={{ width: '100%' }}>
            <colgroup>
              <col style={{ width: '100%' }} />
            </colgroup>
            <tbody style={{ width: '100%' }}>
              <tr style={{ width: '100%' }}>
                <td style={{ width: '100%' }}>
                  <AtoZBannerText>
                    <AtoZBannerIcon
                      style={{ marginBottom: '-3px' }}
                      src={require('../images/general/mindful_icon_original.png')}
                    ></AtoZBannerIcon>
                    <AtoZBannerText1>
                      Vivablee A-Z
                  </AtoZBannerText1>
                    <AtoZBannerText2>
                      認識心理健康及冥想
                  </AtoZBannerText2>
                    <BannerBtn onClick={toAtozDetail}>了解更多</BannerBtn>
                  </AtoZBannerText>
                </td>
              </tr>
              <tr style={{ width: '100%' }}>
                <td style={{ width: '100%' }}>
                  <AtoZBanner style={{ width: '100%', height: '300px', marginTop: '30px' }} />
                </td>
              </tr>
            </tbody>
          </AtoZTopBannerTable768>
        </AtoZTopBannerDiv>
      </center>
      <svg
        style={{ backgroundColor: 'white', marginBottom: '-10px' }}
        width="100%"
        //height="auto"
        viewBox="0 0 3840 260"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>Path</title>
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <path
            d="M-9.09494702e-13,0.5 L-9.09494702e-13,259.5 C543.289571,86.8333333 1184.99624,0.5 1925.12,0.5 C2499.39149,0.5 3137.68482,86.8333333 3840,259.5 L3840,0.5 L-9.09494702e-13,0.5 Z"
            id="Path"
            fill="#f9f9f9"
            transform="translate(1920.000000, 130.000000) scale(1, -1) translate(-1920.000000, -130.000000) "
          ></path>
        </g>
      </svg>
      <div id="demoScroll"></div>
      <AtoZContentDiv style={{ backgroundColor: '#f9f9f9' }}>
        <div style={{ marginTop: '50px' }}>
          <AtoZMenu style={{
            listStyle: 'none',
          }}>
            {alphabet.map((item, i) => {
              return (
                item === "ALL" && selectedLetter === "ALL" ?
                  <li className="atozlist" key={i}>
                    <button className="atozbuttonALL"
                      onClick={() => { setLoading(true); setSelectedLetter(item); }}
                    >
                      {item}
                    </button>
                  </li>
                  :
                  item === selectedLetter ?
                    <li className="atozlist" key={i}>
                      <button className="atozbuttonSEL"
                        onClick={() => { setLoading(true); setSelectedLetter(item); }}
                        disabled={true}
                      >
                        {item}
                      </button>
                    </li>
                    :
                    isEmptyAtoZ(item) ?
                      <li className="atozlist" key={i}>
                        <button className="atozbuttonEMP"
                          onClick={() => { setLoading(true); setSelectedLetter(item); }}
                          disabled={true}
                        >
                          {item}
                        </button>
                      </li>
                      :
                      item === "ALL" ?
                        <li className="atozlist" key={i}>
                          <button className="atozbuttonALL2"
                            onClick={() => { setLoading(true); setSelectedLetter(item); }}
                          >
                            {item}
                          </button>
                        </li>
                        :
                        <li className="atozlist" key={i}>
                          <button className="atozbutton"
                            onClick={() => { setLoading(true); setSelectedLetter(item); }}
                          >
                            {item}
                          </button>
                        </li>
              )
            }
            )}
          </AtoZMenu>
          <AtoZMenu768>
            {alphabet.map((item, i) => {
              return (
                item === "ALL" && selectedLetter === "ALL" ?
                  <li className="atozlist" key={i}>
                    <button className="atozbuttonALL"
                      onClick={() => { setLoading(true); setSelectedLetter(item); }}
                      key={i}
                    >
                      {item}
                    </button>
                  </li>
                  :
                  item === selectedLetter ?
                    <li className="atozlist" key={i}>
                      <button className="atozbuttonSEL"
                        onClick={() => { setLoading(true); setSelectedLetter(item); }}
                        disabled={true}
                        key={i}
                      >
                        {item}
                      </button>
                    </li>
                    :
                    isEmptyAtoZ(item) ?
                      <li className="atozlist" key={i}>
                        <button className="atozbuttonEMP"
                          onClick={() => { setLoading(true); setSelectedLetter(item); }}
                          disabled={true}
                          key={i}
                        >
                          {item}
                        </button>
                      </li>
                      :
                      item === "ALL" ?
                        <li className="atozlist" key={i}>
                          <button className="atozbuttonALL2"
                            onClick={() => { setLoading(true); setSelectedLetter(item); }}
                            key={i}
                          >
                            {item}
                          </button>
                        </li>
                        :
                        <li className="atozlist" key={i}>
                          <button className="atozbutton"
                            onClick={() => { setLoading(true); setSelectedLetter(item); }}
                            key={i}
                          >
                            {item}
                          </button>
                        </li>
              )
            }
            )}
          </AtoZMenu768>

          <AtoZTable>
            <colgroup>
              <col style={{ width: '45%' }} />
              <col style={{ width: '10%' }} />
              <col style={{ width: '45%' }} />
            </colgroup>
            <tbody>
              {loading ?
                <tr style={{ verticalAlign: "top" }}>
                  <td>
                    <AtoZHr />
                    <Skeleton style={{ height: 60, marginTop: '-10px', marginBottom: '-10px' }} />
                    <AtoZHr />
                    <Skeleton style={{ height: 60, marginTop: '-10px', marginBottom: '-10px' }} />
                    <AtoZHr />
                    <Skeleton style={{ height: 60, marginTop: '-10px', marginBottom: '-10px' }} />
                    <AtoZHr />
                    <Skeleton style={{ height: 60, marginTop: '-10px', marginBottom: '-10px' }} />
                    <AtoZHr />
                  </td>
                  <td />
                  <td>
                    <AtoZHr />
                    <Skeleton style={{ height: 60, marginTop: '-10px', marginBottom: '-10px' }} />
                    <AtoZHr />
                    <Skeleton style={{ height: 60, marginTop: '-10px', marginBottom: '-10px' }} />
                    <AtoZHr />
                    <Skeleton style={{ height: 60, marginTop: '-10px', marginBottom: '-10px' }} />
                    <AtoZHr />
                    <Skeleton style={{ height: 60, marginTop: '-10px', marginBottom: '-10px' }} />
                    <AtoZHr />
                  </td>
                </tr>
                :
                <tr style={{ verticalAlign: "top" }}>
                  <td>
                    <AtoZHr />
                    {tempOptionList.slice(0, (tempOptionList.length + 1) / 2).map((item, i) =>
                      <div key={i}>
                        {/* <details>
                        <summary className="atoztitle">
                          {item.option_title_chi} ({item.option_title})
                        </summary>
                        <div className="atozcontent">
                          {htmlToReactParser.parse(item.option_content)}
                        </div>
                      </details> */}
                        <h1 className="atoztitle" onClick={() => { window.location.href = ('/AtoZ/' + item.option_title) }}>
                          {item.option_title_chi} - {item.option_title}
                        </h1>
                        <AtoZHr />
                      </div>
                    )}
                  </td>
                  <td />
                  <td>
                    <AtoZHr />
                    {tempOptionList.slice((tempOptionList.length + 1) / 2, tempOptionList.length).map((item, i) =>
                      <div key={i}>
                        {/* <details>
                        <summary className="atoztitle">
                          {item.option_title_chi} ({item.option_title})
                        </summary>
                        <div className="atozcontent">
                          {htmlToReactParser.parse(item.option_content)}
                        </div>
                      </details> */}
                        <h1 className="atoztitle" onClick={() => { window.location.href = ('/AtoZ/' + item.option_title) }}>
                          {item.option_title_chi} - {item.option_title}
                        </h1>
                        <AtoZHr />
                      </div>
                    )}
                  </td>
                </tr>
              }
            </tbody>
          </AtoZTable>
          <AtoZTable768>
            <colgroup>
              <col style={{ width: '100%' }} />
            </colgroup>
            <tbody>
              {loading ?
                <tr style={{ verticalAlign: "top" }}>
                  <td>
                    <AtoZHr />
                    <Skeleton style={{ height: 60, marginTop: '-10px', marginBottom: '-10px' }} />
                    <AtoZHr />
                    <Skeleton style={{ height: 60, marginTop: '-10px', marginBottom: '-10px' }} />
                    <AtoZHr />
                    <Skeleton style={{ height: 60, marginTop: '-10px', marginBottom: '-10px' }} />
                    <AtoZHr />
                    <Skeleton style={{ height: 60, marginTop: '-10px', marginBottom: '-10px' }} />
                    <AtoZHr />
                  </td>
                </tr>
                :
                <tr>
                  <td>
                    <AtoZHr />
                    {tempOptionList.slice(0, tempOptionList.length)
                      .map((item, i) => {
                        return (
                          <div key={i}>
                            {/* <details>
                            <summary className="atoztitle">
                              {item.option_title_chi} ({item.option_title})
                              </summary>
                            <div className="atozcontent">
                              {
                                htmlToReactParser.parse(item.option_content)
                              }
                            </div>
                          </details> */}
                            <h1 className="atoztitle" onClick={() => { window.location.href = ('/AtoZ/' + item.option_title) }}>
                              {item.option_title_chi} - {item.option_title}
                            </h1>
                            <AtoZHr />
                          </div>
                        )
                      }
                      )}
                  </td>
                </tr>
              }
            </tbody>
          </AtoZTable768>
        </div>
      </AtoZContentDiv>
      <svg
        style={{ marginTop: '-50px', backgroundColor: 'white' }}
        width="100%"
        //height="auto"
        viewBox="0 0 3840 259"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>Path</title>
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <path
            d="M0,0 C539.876237,172.666667 1179.87624,259 1920,259 C2494.27149,259 3134.27149,172.666667 3840,0 L0,0 Z"
            id="Path"
            fill="#f9f9f9"
          ></path>
        </g>
      </svg>
      <Footer />
    </div>
  );
}

export default AtoZ;
